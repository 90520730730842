import * as React from 'react';

function MyProbationsIcons(props) {
	return (
        <svg
                xmlns='http://www.w3.org/2000/svg'
                height='1em'
                viewBox='0 0 24 24'
                width='1em'
                className='svg-icon'
                {...props}>
        <path d="M18.6034 0H3.04781C1.82559 0 0.825592 1 0.825592 2.22222V17.7778C0.825592 19 1.82559 20 3.04781 20H18.6034C19.8256 20 20.8256 19 20.8256 17.7778V2.22222C20.8256 1 19.8256 0 18.6034 0ZM18.6034 16.7778C18.6034 17.3301 18.1557 17.7778 17.6034 17.7778H4.04782C3.49553 17.7778 3.04781 17.3301 3.04781 16.7778V3.22222C3.04781 2.66994 3.49553 2.22222 4.04781 2.22222H17.6034C18.1557 2.22222 18.6034 2.66994 18.6034 3.22222V16.7778ZM6.27004 8.77778C6.27004 8.22549 6.71775 7.77778 7.27004 7.77778H7.49226C8.04454 7.77778 8.49226 8.22549 8.49226 8.77778V14.5556C8.49226 15.1078 8.04454 15.5556 7.49226 15.5556H7.27004C6.71775 15.5556 6.27004 15.1078 6.27004 14.5556V8.77778ZM9.71448 5.44444C9.71448 4.89216 10.1622 4.44444 10.7145 4.44444H10.9367C11.489 4.44444 11.9367 4.89216 11.9367 5.44444V14.5556C11.9367 15.1078 11.489 15.5556 10.9367 15.5556H10.7145C10.1622 15.5556 9.71448 15.1078 9.71448 14.5556V5.44444ZM13.1589 12.1111C13.1589 11.5588 13.6066 11.1111 14.1589 11.1111H14.3811C14.9334 11.1111 15.3811 11.5588 15.3811 12.1111V14.5556C15.3811 15.1078 14.9334 15.5556 14.3811 15.5556H14.1589C13.6066 15.5556 13.1589 15.1078 13.1589 14.5556V12.1111Z" fill="white"/>
        </svg>
        
	);
}

export default MyProbationsIcons;