import * as React from 'react';

const SvgEmailCreateIcon = (props) => (
    <svg width="52" height="40" viewBox="0 0 52 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13.4649 1.09686C17.8389 -0.404758 22.5952 -0.363457 26.9425 1.21389C29.3299 2.10779 31.5173 3.46431 33.3792 5.20561C32.7466 5.8825 32.0792 6.5151 31.434 7.17301C30.2067 8.39815 28.9805 9.61907 27.7554 10.8358C26.5341 9.6676 25.0567 8.80108 23.441 8.30536C21.3736 7.68111 19.1742 7.63837 17.0841 8.18182C14.994 8.72528 13.0939 9.83393 11.5924 11.3861C10.2818 12.7195 9.28918 14.3316 8.68876 16.1022L2.13184 11.0256C4.47882 6.37138 8.54247 2.81129 13.4649 1.09686Z" fill="#E33629" />
        <path d="M0.392204 16.0547C0.744373 14.308 1.32949 12.6165 2.13186 11.0256L8.68878 16.1148C7.83126 18.6409 7.83126 21.3793 8.68878 23.9053C6.5042 25.5923 4.31856 27.2877 2.13186 28.9915C0.123825 24.9944 -0.488591 20.4403 0.392204 16.0547Z" fill="#F8BD00" />
        <path d="M20.006 16.2603H38.8323C39.4759 19.7932 39.3017 23.4266 38.323 26.8817C37.4223 30.0589 35.6727 32.9305 33.2622 35.1878C31.1462 33.5367 29.0206 31.8983 26.9046 30.2472C27.9537 29.5395 28.8491 28.6274 29.5372 27.5654C30.2253 26.5033 30.6918 25.3133 30.9089 24.0666H20.006C20.0029 21.4667 20.006 18.8635 20.006 16.2603Z" fill="#587DBD" />
        <path d="M38 25.5C38 26.163 38.2634 26.7989 38.7322 27.2678C39.2011 27.7366 39.837 28 40.5 28C41.163 28 41.7989 27.7366 42.2678 27.2678C42.7366 26.7989 43 26.163 43 25.5V20H48.5C49.163 20 49.7989 19.7366 50.2678 19.2678C50.7366 18.7989 51 18.163 51 17.5C51 16.837 50.7366 16.2011 50.2678 15.7322C49.7989 15.2634 49.163 15 48.5 15H43V9.5C43 8.83696 42.7366 8.20107 42.2678 7.73223C41.7989 7.26339 41.163 7 40.5 7C39.837 7 39.2011 7.26339 38.7322 7.73223C38.2634 8.20107 38 8.83696 38 9.5V15H32.5C31.837 15 31.2011 15.2634 30.7322 15.7322C30.2634 16.2011 30 16.837 30 17.5C30 18.163 30.2634 18.7989 30.7322 19.2678C31.2011 19.7366 31.837 20 32.5 20H38V25.5Z" fill="#587DBD" stroke="white" stroke-width="2" />
        <path d="M2.12872 28.9915C4.31542 27.3045 6.50106 25.6092 8.68565 23.9053C9.53083 26.3858 11.1383 28.5357 13.2783 30.0479C14.613 30.9916 16.1298 31.6466 17.7319 31.971C19.3104 32.2646 20.9295 32.2646 22.508 31.971C24.082 31.705 25.5829 31.1124 26.9141 30.2314C29.0301 31.8825 31.1557 33.5209 33.2717 35.172C30.9561 37.2777 28.1332 38.7458 25.0795 39.4326C21.7091 40.2249 18.1966 40.1869 14.844 39.3219C12.1925 38.6139 9.71576 37.3658 7.5691 35.6559C5.2972 33.8518 3.44148 31.5786 2.12872 28.9915Z" fill="#319F43" />
    </svg>

);

export default SvgEmailCreateIcon;
