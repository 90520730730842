import axios, { AxiosResponseHeaders } from 'axios';
import Cookies from 'js-cookie';

const axiosInstance = axios.create();

axiosInstance.interceptors.request.use(
    (config) => {
        if(config && config.headers) {
            const token = Cookies.get('idToken');
            config.headers = {
                ...config.headers,
                Authorization: token,
            };
        }
        return config;
    },
    error => {
        Promise.reject(error);
});

axiosInstance.interceptors.response.use((response)=> {
    if(response && response.headers) {
		// console.log("axios interceptor :: idToken", response.headers.get('idToken'));
        if(response.headers != undefined && typeof response.headers !== 'string') {
            const newIDToken = (response.headers as AxiosResponseHeaders).get('idToken');
            if(newIDToken && newIDToken !== '') {
                Cookies.set('idToken', newIDToken?.toString() || '');
            }
        }
	}

    return response;
    }, function(error){
    return Promise.reject(error);
});

export default axiosInstance;