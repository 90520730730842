// useColourStyles.tsx
import { useEffect, useState } from "react";
import useDarkMode from "../../hooks/useDarkMode";

interface ColourStyles {
  option: (styles: any, { isFocused }: any) => any;
  control: (styles: any) => any;
  singleValue: (styles: any) => any;
  menuList: (styles: any, { data, isDisabled, isFocused, isSelected }: any) => any; // Add menuList property
}
interface ColourStylesprops {
  option: (styles: any, { isFocused }: any) => any;
  control: (styles: any) => any;
  singleValue: (styles: any) => any;
  menuList: (styles: any, { data, isDisabled, isFocused, isSelected }: any) => any; // Add menuList property
}

const SelectStyles = (isError: any = false): ColourStyles => {
  const { darkModeStatus } = useDarkMode();

  const colourStyles: ColourStyles = {
    option: (styles, { isFocused }) => {
      if (darkModeStatus) {
        
        return {
          ...styles,
          backgroundColor: isFocused ? "#212529" : "#212529",
          color: "#e7eef8", // Text color in dark mode
        };
      }
      return styles; // Return the default styles when not in dark mode
    },
    menuList: (styles: any, { data, isDisabled, isFocused, isSelected }: any) => {
      if (darkModeStatus) {
        return {
          ...styles,
          backgroundColor: "#212529",
        };
      }
      return styles; // Return the default styles when not in dark mode
    },
    control: (styles) => ({
      ...styles,
      backgroundColor: darkModeStatus ? "#212529" : styles.backgroundColor,
      color: "red",
      input: {
        color: "red", // Set the text color of the input directly
      },
      borderColor: isError ? '#f35421' : styles.borderColor,
      
    }),
    singleValue: (styles) => ({
      ...styles,
      color: darkModeStatus ? "#e7eef8" : styles.color,
    }),
  };

  return colourStyles;
};

export default SelectStyles;
