import * as React from 'react';

const SvgGoogleGroupAddIcon = (props) => (
    <svg width="52" height="40" viewBox="0 0 52 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.00210932 27.1595C0.249954 26.5985 0.236243 25.9804 0.378622 25.3951C1.6653 20.1092 5.90503 16.2618 11.2954 15.5657C14.8517 15.1069 18.0589 16.0055 20.9033 18.1897C20.9982 18.2624 21.0763 18.3563 21.1617 18.4407C21.1638 18.5567 21.1026 18.6326 21.0162 18.7033C18.9079 20.4308 17.6529 22.6825 16.9462 25.2801C16.478 27.0024 16.6678 28.7563 16.6425 30.5007C16.6404 30.6167 16.7016 30.7359 16.7448 30.8487C17.0549 31.6671 17.6993 32.0658 18.5135 32.2483C18.3226 32.262 18.1317 32.2873 17.9408 32.2873C15.5414 32.2905 13.141 32.301 10.7417 32.2778C10.2966 32.2736 9.99815 32.4149 9.69336 32.7229C7.54818 34.887 5.38297 37.0322 3.23253 39.1911C2.8982 39.5265 2.54594 39.8197 2.10615 40H1.36895C0.67709 39.7785 0.248899 39.2955 0 38.6311V27.1574L0.00210932 27.1595Z" fill="#2087FB" />
        <path d="M42.4288 30.6325C42.2886 30.681 42.2886 30.8255 42.2401 30.9289C41.8393 31.7948 41.1464 32.2746 40.2141 32.2778C34.849 32.2957 29.485 32.2841 24.12 32.281C24.0704 32.281 24.0198 32.2535 23.9702 32.2388C25.0745 32.0131 25.7906 31.3349 25.8707 30.2296C25.9783 28.7352 25.9519 27.226 25.6187 25.7474C25.027 23.1202 23.8405 20.8305 21.8124 19.0123C21.6257 18.8446 21.3705 18.7043 21.3621 18.3964C22.8259 17.0749 24.5619 16.2691 26.4539 15.8093C33.9357 13.989 41.3193 19.0577 42.3213 26.6797C42.3339 26.7767 42.2991 26.8969 42.4288 26.9476V30.6315V30.6325Z" fill="#2087FB" />
        <path d="M13.8983 0.00105286C14.7842 0.292138 15.6912 0.507288 16.5033 1.01563C18.8784 2.5027 20.1229 5.345 19.5565 8.08922C18.987 10.8472 16.7385 12.9786 13.9689 13.3825C10.3462 13.912 6.90905 11.4831 6.32688 7.98481C5.69935 4.20281 8.06179 0.79521 11.7889 0.108628C11.8723 0.0928081 11.9672 0.108628 12.003 0.00105286H13.8983Z" fill="#2087FB" />
        <path d="M30.7433 0.00105466C31.6292 0.29214 32.5362 0.50729 33.3483 1.01564C35.7234 2.5027 36.9679 5.34501 36.4015 8.08922C35.832 10.8472 33.5835 12.9786 30.814 13.3826C27.1912 13.912 23.7541 11.4831 23.1719 7.98481C22.5433 4.20281 24.9058 0.794157 28.6329 0.107575C28.7162 0.0917552 28.8112 0.107575 28.847 0H30.7422L30.7433 0.00105466Z" fill="#2087FB" />
        <path d="M21.3621 18.3974C21.3705 18.7054 21.6247 18.8457 21.8124 19.0134C23.8405 20.8316 25.027 23.1213 25.6187 25.7484C25.952 27.2281 25.9794 28.7363 25.8708 30.2307C25.7906 31.336 25.0745 32.0141 23.9703 32.2398C22.152 32.3221 20.3327 32.3073 18.5135 32.2483C17.6993 32.0658 17.0549 31.6671 16.7448 30.8487C16.7016 30.7348 16.6404 30.6157 16.6425 30.5007C16.6689 28.7563 16.478 27.0013 16.9462 25.2801C17.6529 22.6815 18.9079 20.4298 21.0162 18.7033C21.1026 18.6326 21.1638 18.5556 21.1617 18.4407C21.2282 18.427 21.2946 18.4122 21.361 18.3985L21.3621 18.3974Z" fill="#0069D8" />
        <path d="M38 26.5C38 27.163 38.2634 27.7989 38.7322 28.2678C39.2011 28.7366 39.837 29 40.5 29C41.163 29 41.7989 28.7366 42.2678 28.2678C42.7366 27.7989 43 27.163 43 26.5V21H48.5C49.163 21 49.7989 20.7366 50.2678 20.2678C50.7366 19.7989 51 19.163 51 18.5C51 17.837 50.7366 17.2011 50.2678 16.7322C49.7989 16.2634 49.163 16 48.5 16H43V10.5C43 9.83696 42.7366 9.20107 42.2678 8.73223C41.7989 8.26339 41.163 8 40.5 8C39.837 8 39.2011 8.26339 38.7322 8.73223C38.2634 9.20107 38 9.83696 38 10.5V16H32.5C31.837 16 31.2011 16.2634 30.7322 16.7322C30.2634 17.2011 30 17.837 30 18.5C30 19.163 30.2634 19.7989 30.7322 20.2678C31.2011 20.7366 31.837 21 32.5 21H38V26.5Z" fill="#2087FB" stroke="white" stroke-width="2" />
    </svg>

);

export default SvgGoogleGroupAddIcon;
