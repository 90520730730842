import React from 'react';
import { useCallback, useEffect, useState } from 'react';
import PageWrapper from '../layout/PageWrapper/PageWrapper';
import Page from '../layout/Page/Page';
import Card, {
	CardActions,
	CardBody,
	CardHeader,
	CardLabel,
	CardTitle,
} from '../components/bootstrap/Card';
import { dashboardMenu } from '../menu';
import PaginationButtons from '../components/PaginationButtons';
import useTourStep from '../hooks/useTourStep';
import prvnIcon from '../assets/img/Prvn.png';
import csatIcon from '../assets/img/cst.png';
import useDarkMode from '../hooks/useDarkMode';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { processVinePageTitle, ProjectStatus, ProjectType } from '../App/Constants';
import Icon from '../components/icon/Icon';
import SubHeader, { SubHeaderLeft, SubHeaderRight } from '../layout/SubHeader/SubHeader';
import Input from '../components/bootstrap/forms/Input';
import FormGroup from '../components/bootstrap/forms/FormGroup';
import Button from '../components/bootstrap/Button';
import Dropdown, { DropdownToggle, DropdownMenu } from '../components/bootstrap/Dropdown';
import { ChecksGroup } from '../components/bootstrap/forms/Checks';
import Popovers from '../components/bootstrap/Popovers';
import Cookies from 'js-cookie';
import Spinner from '../components/bootstrap/Spinner';
import { Multiselect } from 'multiselect-react-dropdown';
import {
	SortingState,
	createColumnHelper,
	flexRender,
	getCoreRowModel,
	useReactTable,
} from '@tanstack/react-table';
import { serviceURL } from '../types';
import Pagination from '@mui/material/Pagination';
import Grid from '@mui/material/Grid';
import { ProjectFilter } from './components/ProjectFilter';
import { Type } from './types/index';
import AddProject from '../pages/projectmembers/addProject';
import Alert, { AlertHeading } from '../components/bootstrap/Alert';
import { getCreateAccess, getJobStatus } from '../common/data/service';
import { Link } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
const columnHelper: any = createColumnHelper();

const columnss = [
	columnHelper.accessor('client_name', {
		header: 'Account',
	}),
	columnHelper.accessor('project_name', {
		header: 'Project',
	}),
	columnHelper.accessor('project_type', {
		header: 'Type',
	}),
	columnHelper.accessor('project_status', {
		header: 'Status',
	}),
	columnHelper.accessor('tscore', {
		header: 'PRVN KPI',
	}),
	columnHelper.accessor('csatscore', {
		header: 'CSAT KPI',
	}),
	columnHelper.accessor('projectAction', {
		header: 'Action',
	}),
];

async function LoadServerRows(pagination: any, globalFilter: any, sorting: any, popUpFilter: any) {
	const token = Cookies.get('idToken') || '';

	var headers = {
		Accept: 'application/json',
		'Content-Type': 'application/json',
		authorization: `${token}`,
	};

	var filter =
		globalFilter != ''
			? {
					filters: {
						q: globalFilter,
						popUpFilter: popUpFilter,
					},
			  }
			: { filters: { popUpFilter: popUpFilter } };
	var range = [pagination.pageIndex, pagination.pageSize];
	var sort = sorting.length > 0 ? [sorting[0].id, sorting[0].desc] : ['0', '0'];
	return fetch(
		serviceURL +
			`/api/progressvine/project-lists-beta` +
			'?filter=' +
			encodeURIComponent(JSON.stringify(filter)) +
			'&range=' +
			encodeURIComponent(JSON.stringify(range)) +
			'&sort=' +
			encodeURIComponent(JSON.stringify(sort)),
		{
			method: 'GET',
			headers: headers,
		},
	)
		.then((response) => {
			var contentRangeArr = response.headers.get('Content-Range')!.split('/');
			var result = response.json();
			return [result, contentRangeArr[1]];
		})
		.then((r) => {
			return r;
		})
		.catch((e: any) => {
			console.log(e);
			return [[], '0'];
		})

		.finally(() => {});
}

const DebouncedInput = (props: any) => {
	const [value, setValue] = useState(props.value);
	const [closeBtn, setCloseBtn] = useState(false);

	useEffect(() => {
		setValue(props.value);
		if (props.value) setCloseBtn(true);
		else setCloseBtn(false);
	}, [props.value]);

	useEffect(() => {
		const timeout = setTimeout(() => {
			props.onChange(value);
		}, props.debounce);

		return () => clearTimeout(timeout);
	}, [value, props]);

	return (
		<>
			<label className='border-0 bg-transparent cursor-pointer me-0' htmlFor='searchInput'>
				<Icon icon='Search' size='2x' color='primary' />
			</label>
			<Input
				id='searchInput'
				className='border-0 shadow-none bg-transparent'
				placeholder={props.placeholder}
				onChange={(event: any) => {
					setValue(event.target.value);
				}}
				onFocus={props.onFocus}
				onBlur={props.onBlur}
				value={value}
			/>
			<Button
				className={classNames({ 'processvine-btn-close': !closeBtn }, 'btn-close')}
				type='button'
				aria-label='Close'
				onClick={() => {
					setValue('');
					setCloseBtn(false);
				}}
			/>
		</>
	);
};

const ProcessVinePage = () => {
	const navigate = useNavigate();

	const [newProject, setNewProject] = useState<boolean>(false);
	const [createAccess, setCreateAccess] = useState<boolean>(false);

	const handleSelectStatus = (selectedList: Type.ProjectStatus[], selectedItem?: any) => {
		setStatusFilterValue(selectedList);
	};
	const handleSelectType = (selectedList: Type.ISelect[], selectedItem?: any) => {
		setTypeFilterValue(selectedList);
	};

	const [projectFilterName, setProjectFilterName] = useState('');
	const [data, setData] = useState({
		rows: [],
		rowCount: 0,
	});
	const [pagination, setPagination] = useState({
		pageIndex: 0, // page index matlab = page number
		pageSize: 5, // page size matlab = limit
	});
	const [globalFilter, setGlobalFilter] = useState('');
	const [sorting, setSorting] = useState<SortingState>([]);
	const [loading, setLoading] = useState<boolean>(false);
	let [apiCall, setApiCall] = useState(true);
	const [jobStatus, setJobStatus] = useState('');
	const [statusFilterValue, setStatusFilterValue] = useState<Type.ProjectStatus[]>([
		{ key: 1, value: 'Initiated' },
		{ key: 2, value: 'Draft' },
		{ key: 3, value: 'In-progress' },
		{ key: 4, value: 'Hold' },
		{ key: 5, value: 'In_Pipeline' },
		{ key: 6, value: 'POC' },
		{ key: 7, value: 'R&D' },
		{ key: 8, value: 'Active' },
		{ key: 9, value: 'Deferred' },
		{ key: 10, value: 'Support' },
	]);
	const [typeFilterValue, setTypeFilterValue] = useState<any[]>([]);
	const [popUpFilter, setPopUpFilter] = useState<{ statusVal: string[]; typeVal: string[] }>({
		statusVal: [
			'Initiated',
			'Draft',
			'In-progress',
			'Hold',
			'In_Pipeline',
			'POC',
			'R&D',
			'Active',
			'Deferred',
			'Support',
		],
		typeVal: [],
	});
	const [renderProjList, setRenderProjList] = useState(false);
	const [isSearchInputFocused, setIsSearchInputFocused] = useState(false);

	const [created, setCreated] = useState(false);
	const handleApplyFilter = () => {
		let statusChoice = [];
		for (let i = 0; i < statusFilterValue.length; i++) {
			statusChoice.push(statusFilterValue[i].value);
		}
		let typeChoice = [];
		for (let i = 0; i < typeFilterValue.length; i++) {
			typeChoice.push(typeFilterValue[i].value);
		}
		setPopUpFilter({ statusVal: statusChoice, typeVal: typeChoice });
	};
	const handleClearFilter = () => {
		setStatusFilterValue([]);
		setTypeFilterValue([]);
		setPopUpFilter({ statusVal: [], typeVal: [] });
	};

	const renderProcessVineCategory = (row: any, type: any) => {
		// setProjectName(row.original.id);
		// setAccountName(row.original.clientId);
		navigate(
			`../${dashboardMenu.processvineCategorypage.path}/${type}/${row.original.client_name}/${row.original.project_name}`,
			{
				state: {
					accountId: row.original.clientId,
					clientName: row.original.client_name,
					projectId: row.original.id,
					projectName: row.original.project_name,
					tscore: row.original.tscore,
					projectStatus: row.original.project_status,
					projectType: row.original.project_type,
					type: type,
					csatscore: row.original.csatscore,
				},
			},
		);
		// setRenderComponent(true);
	};

	const getRenderProjectMembersLink = (row: any, type: any) => {
		const encodedProjectId = window.btoa(row.original.id);
		return `../${
			dashboardMenu.projectMembership.path
		}/${row.original.project_name.toLowerCase()}/${type}/?id=${encodedProjectId}`;
	};

	const renderProjectMembers = (row: any, type: any) => {
		setRenderProjList(true);
	};

	const scoreModule = (row: any, type: any) => {};

	// const useDidMountEffect = (func: any, deps: any) => {
	// 	const didMount = useRef(false);

	// 	useEffect(() => {
	// 		if (didMount.current) func();
	// 		else didMount.current = true;
	// 	}, deps);
	// };

	// const c = useCallback()

	const { addToast,removeAllToasts } = useToasts();

	const callApi = useCallback(
		(
			paginationVal: any,
			globalFiltercal: any,
			sortingVal: any,
			_popUpFilter: any,
			createdProject?: any,
		) => {
			let active = true;
			!apiCall
				? console.log('failed to add values to filter')
				: (async () => {
						setLoading(true);
						const newData = await LoadServerRows(
							paginationVal,
							globalFiltercal,
							sortingVal,
							_popUpFilter,
						);
						var p = Promise.resolve(newData[0]);
						var r = Promise.resolve(newData[1]);
						var newRows: any;
						var rowCount: string;
						newRows = await p.then(function (v) {
							return v;
						});
						rowCount = await r.then(function (v) {
							return v;
						});
						if (!active) {
							return;
						}
						setData((d) => ({
							...d,
							rowCount: parseInt(rowCount),
							rows: newRows,
						}));
						setLoading(false);
				  })();
			return () => {
				active = false;
			};
		},
		[apiCall],
	);

	const table = useReactTable({
		data: data.rows,
		columns: columnss,
		state: {
			pagination,
			sorting,
			globalFilter,
		},
		manualFiltering: true,
		onGlobalFilterChange: setGlobalFilter,
		manualSorting: true,
		sortDescFirst: false,
		onSortingChange: setSorting,
		manualPagination: true,
		onPaginationChange: setPagination,
		getCoreRowModel: getCoreRowModel(),
	});

	useEffect(() => {
		table.setPagination({
			...table.getState().pagination,
			pageIndex: 0,
		});
		callApi(
			{ pageIndex: 0, pageSize: table.getState().pagination.pageSize },
			globalFilter,
			sorting,
			popUpFilter,
		);
	}, [globalFilter, sorting, popUpFilter, callApi, table]);

	const addNewProject = () => {
		setNewProject(!newProject);
	};

	const reloadProjects = useCallback(() => {
		callApi(
			{ pageIndex: 0, pageSize: table.getState().pagination.pageSize },
			'',
			sorting,
			popUpFilter,
			true,
		);
	}, [callApi, popUpFilter, sorting, table]);

	const fetchJobStatus = useCallback(() => {
		const jobId = JSON.parse(localStorage.getItem('jobStatus')!)?.jobId;
	
		getJobStatus(jobId)
			.then((res) => {
				const status = res?.data?.status;
				setJobStatus(status);
				if (status !== "completed" && status !== "failed") {
					setTimeout(fetchJobStatus, 1000);
				}
				else if (status === "completed") {
					const successMessage = (JSON.parse(localStorage.getItem('jobStatus')!).updated) ? "Google Groups updated successfully" : "Google Groups created successfully"
					localStorage.removeItem('jobStatus') 
					reloadProjects()
					removeAllToasts()

					addToast(successMessage, {id: 'ACCESS_DENIED', appearance: "success", autoDismiss: true})
					
				}
				else if (status === "failed") {
					const failureMessage = (JSON.parse(localStorage.getItem('jobStatus')!).updated) ? "Failed to update Google Groups, Please update the groups manually" : "Failed to create Google Groups; hence, the project creation is reverted back"
					localStorage.removeItem('jobStatus')
					reloadProjects()
					removeAllToasts()
					addToast(failureMessage, {id: 'ACCESS_DENIED', appearance: "error", autoDismiss: false});
				}
			})
			.catch((e) => {
				console.log(e.message);
			});
	}, [reloadProjects, addToast, removeAllToasts]); 
	useEffect(() => {
		getCreateAccess()
			.then((res) => {
				setCreateAccess(res?.data?.create_access);
			})
			.catch((e) => {
				console.log(e.message);
			});
		if(JSON.parse(localStorage.getItem('jobStatus')!)){
			fetchJobStatus()
		}
	}, [fetchJobStatus]);
	
	return (
		<>
			<PageWrapper title={processVinePageTitle}>
				<Page>
					<Card stretch data-tour='list'>
						<CardHeader>
							<CardLabel>
								<CardTitle>Projects</CardTitle>
							</CardLabel>
							<CardActions>
								{createAccess && (
									<Button
										icon='Plus'
										className='btn btn-light-info'
										onClick={() => addNewProject()}>
										Add New Project
									</Button>
								)}
							</CardActions>
						</CardHeader>
						<SubHeader
							className={classNames({
								'project-search-input-container': true,
								'border-blue': isSearchInputFocused,
							})}>
							<SubHeaderLeft>
								<DebouncedInput
									value={globalFilter ?? ''}
									onChange={(value: any) => {
										setGlobalFilter(String(value));
									}}
									onFocus={() => setIsSearchInputFocused(true)}
									onBlur={() => setIsSearchInputFocused(false)}
									placeholder='Search by Account and Projects...'
									debounce={500}
								/>
							</SubHeaderLeft>
							<ProjectFilter
								projectFilterName={projectFilterName}
								statusFilterValue={statusFilterValue}
								typeFilterValue={typeFilterValue}
								ProjectStatus={ProjectStatus}
								ProjectType={ProjectType}
								handleApplyFilter={handleApplyFilter}
								handleClearFilter={handleClearFilter}
								handleSelectStatus={handleSelectStatus}
								handleSelectType={handleSelectType}
							/>
						</SubHeader>
						<CardBody
							className='table-responsive processvine-table-header'
							isScrollable>
							<table className='table table-modern table-hover processvine-table'>
								<thead>
									{table.getHeaderGroups().map((headerGroup) => (
										<tr key={headerGroup.id}>
											{headerGroup.headers.map((header) => (
												<th
													style={{
														textAlign: 'center',
													}}
													key={header.id}
													onClick={
														!header.id.includes('projectAction')
															? header.column.getToggleSortingHandler()
															: undefined
													}>
													{header.id.includes('score') ? (
														<div
															style={{
																textAlign: 'right',
															}}>
															{header.isPlaceholder
																? null
																: flexRender(
																		header.column.columnDef
																			.header,
																		header.getContext(),
																  )}
															{{
																asc: ' ▲',
																desc: ' ▼',
															}[
																header.column.getIsSorted() as string
															] ?? null}
														</div>
													) : (
														<div
															style={{
																textAlign: 'center',
															}}>
															{header.isPlaceholder
																? null
																: flexRender(
																		header.column.columnDef
																			.header,
																		header.getContext(),
																  )}
															{{
																asc: ' ▲',
																desc: ' ▼',
															}[
																header.column.getIsSorted() as string
															] ?? null}
														</div>
													)}
												</th>
											))}
										</tr>
									))}
								</thead>
								<tbody>
									{table.getRowModel().rows.length > 0 ? (
										<>
											{table.getRowModel().rows.map((row) => (
												<tr key={row.id}>
													{row.getVisibleCells().map((cell) => (
														<>
															<td key={cell.id}>
																{!(
																	cell.id.includes(
																		'projectAction',
																	) || cell.id.includes('score')
																) ? (
																	cell.id.includes(
																		'project_name',
																	) ? (
																		<div
																			style={{
																			textAlign:'center',
																		}}>												
																		<Popovers
																			trigger='hover'
																			desc='Click to View the Project'>
																			<Link
																				className='bold-text'

																				to={(JSON.parse(localStorage.getItem('jobStatus') ?? '{}')?.projectId ?? null) == (row?.original as any)?.id ?  '' : getRenderProjectMembersLink(
																					row,
																					'project-details',
																				)}
																				style={{
																					textAlign:
																						'center',
																				}}
																				onClick={() => { 
																					if ((JSON.parse(localStorage.getItem('jobStatus') ?? '{}')?.projectId ?? null) == (row?.original as any)?.id) {
																						const message = (JSON.parse(localStorage.getItem('jobStatus')!).updated) ? "Please wait, Google Groups are being updated" : "Please wait, Google Groups are being created"
																						removeAllToasts()
																						addToast(message, {id: 'ACCESS_DENIED', appearance: "warning", autoDismiss: true});
																						return false
																					} else {
																						renderProjectMembers(row, 'project-details');
																					}
																				}}
															>
																				{flexRender(
																					cell.column
																						.columnDef
																						.cell,
																					cell.getContext(),
																				)}
																			</Link>
																		</Popovers>
																		</div>
																	) : (
																		<div
																			style={{
																				textAlign: 'center',
																			}}>
																			{flexRender(
																				cell.column
																					.columnDef.cell,
																				cell.getContext(),
																			)}
																		</div>
																	)
																) : (
																	<>
																		{cell.id.includes(
																			'score',
																		) ? (
																			<div
																				style={{
																					textAlign:
																						'right',
																				}}>
																				{flexRender(
																					cell.column
																						.columnDef
																						.cell,
																					cell.getContext(),
																				)}
																				{'%'}
																			</div>
																		) : (
																			<div
																				style={{
																					textAlign:
																						'center',
																				}}>
																				<Popovers
																					trigger='hover'
																					desc='Progressvine'>
																					<button
																						className='btn'
																						onClick={() =>
																							renderProcessVineCategory(
																								row,
																								'progressvine',
																							)
																						}>
																						<img
																							src={
																								prvnIcon
																							}
																							className='cursor-pointer'
																							alt=''
																							style={{
																								width: '22px',
																								height: '22px',
																							}}
																						/>
																					</button>
																				</Popovers>

																				<Popovers
																					trigger='hover'
																					desc='CSAT'>
																					<button
																						className='btn'
																						onClick={() =>
																							renderProcessVineCategory(
																								row,
																								'csat',
																							)
																						}>
																						<img
																							src={
																								csatIcon
																							}
																							className='cursor-pointer'
																							alt=''
																							style={{
																								width: '35px',
																								height: '22px',
																							}}
																						/>
																					</button>
																				</Popovers>
																			</div>
																		)}
																	</>
																)}
															</td>
														</>
													))}
												</tr>
											))}
										</>
									) : (
										<div className='text-center processvine-no-projects'>
											No Projects are Mapped
										</div>
									)}
								</tbody>
							</table>
							{newProject && (
								<AddProject
									addNewProject={addNewProject}
									reloadProjects={reloadProjects}
									fetchJobStatus = {fetchJobStatus}								/>
							)}
							{loading && (
								<div className='processvine-overlay-box'>
									<Spinner
										className='text-center processvine-no-projects processvine-spinner'
										inButton
									/>
								</div>
							)}
						</CardBody>
						<PaginationButtons
							currentPage={pagination.pageIndex + 1}
							setCurrentPage={(page: number) => {
								table.setPageIndex(page - 1);

								const tableState = table.getState();
								callApi(
									{ ...tableState.pagination, pageIndex: page - 1 },
									tableState.globalFilter,
									tableState.sorting,
									popUpFilter,
								);
							}}
							resetPageOnPerPageUpdate={false}
							perPage={pagination.pageSize}
							setPerPage={(perPage: number) => {
								table.setPageSize(perPage);
								table.setPageIndex(0);

								const tableState = table.getState();
								callApi(
									{ pageIndex: 0, pageSize: perPage },
									tableState.globalFilter,
									tableState.sorting,
									popUpFilter,
								);
							}}
							data={Array(data.rowCount).fill('*')}
							label='Projects '
						/>
					</Card>
				</Page>
			</PageWrapper>
		</>
	);
};

export default ProcessVinePage;
