import Cookies from "js-cookie";
import { CookieKeys } from "../constants";
import jwtDecode from "jwt-decode";
import { AuthTypes } from "./auth.types";
import { ClientID, redirectURI } from "../types";

export function isSessionExpired() {
	const idToken = Cookies.get(CookieKeys.ID_TOKEN);

	if(idToken) {
		const parsedIDToken: AuthTypes.ParsedIDToken = jwtDecode(idToken);

		if(parsedIDToken && parsedIDToken.exp) {
			const expiryMillisecond = parsedIDToken.exp * 1000;
			const currentMillisecond = Date.now();

            console.log(expiryMillisecond, currentMillisecond, expiryMillisecond < currentMillisecond);

			return expiryMillisecond < currentMillisecond;
		}

        return true;
	}

    return true;
}

export function signInToGoogleWithRedirect() {
    console.log('signInToGoogleWithRedirect');
    const options = {
        redirect_uri: redirectURI || '',
        client_id: ClientID || '',
        access_type: 'offline',
        response_type: 'code',
        scope: [
          'profile',
          'email',
        ].join(' '),
    };

    window.open(`https://accounts.google.com/o/oauth2/v2/auth?${(new URLSearchParams(options)).toString()}`, '_self');
}

export function doesURLHasIDTokenQueryParam() {
    // ? NOTE: Since this runs in index.js (outside React environment), we don't have access to react-router-dom
    const url = new URL(window.location.href);
    const idToken = url.searchParams.get('idToken');

    return idToken !== null;
}

export function autoLogin() {
    // If idToken is in the url, then we are redirected from Google callback.
    // So don't call the sign in with Google again
    if(doesURLHasIDTokenQueryParam()) {
        return;
    }

    if(isSessionExpired()) {
        signInToGoogleWithRedirect();
    }
}