import React, { FC, useEffect, useState } from "react";
import Button from "../../components/bootstrap/Button";
import OffCanvas, {
  OffCanvasBody,
  OffCanvasHeader,
  OffCanvasTitle,
} from "../../components/bootstrap/OffCanvas";
import axios from "axios";
import USERS, {
  IUserProps,
  getAuditLogs,
  getClientRoles,
  getDepartmentList,
  getStatuses,
  getSysvineRoles,
  updateProjectMemberDetail,
} from "../../common/data/service";
import Chat, { ChatGroup, ChatMessages } from "../../components/Chat";
import { parseDate, validateExpiredToken } from "../../App/Constants";
import Spinner from "../../components/bootstrap/Spinner";
import { useNavigate } from "react-router-dom";

const ReviewHistory = (props: any) => {

  // State for controlling the visibility of the off-canvas component
  const [upcomingEventsEditOffcanvas, setUpcomingEventsEditOffcanvas] = useState(false);

  // Close the off-canvas component
  const handleOffCanvasClose = () => {
    props.reviewHistory();
  };


  // State to indicate loading status
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const memberId = props.id;
  const createdUserId = props.created_by;
  const [reviewHistory, setReviewHistory] = useState<any[]>([]);
  const [createdBy, setCreatedBy] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    setIsLoading(true);

  },[]);
  useEffect(() => {
    setIsLoading(true);
    getAuditLogs({id: memberId, created_by: createdUserId})
    .then((res) => {
      setReviewHistory(res?.data?.data ?? []);
      setCreatedBy(res?.data?.created_by ?? null)
      setIsLoading(false);

    })
    .catch((e) => {
      if (!validateExpiredToken(e.message)) {
        navigate('/');
        window.location.reload();
      }
      console.log(e.message);
    });
    setUpcomingEventsEditOffcanvas(true);
  }, [createdUserId, memberId, navigate]);

const [logMessages, setLogMessages] = useState<any[]>([]);


const formatDate = (dateString: string) => {
  const timestamp = new Date(dateString);
  if (isNaN(timestamp.getTime())) {
    const isoTimestamp = new Date(dateString);
    if (!isNaN(isoTimestamp.getTime())) {
      return isoTimestamp.toLocaleDateString();
    }
    return dateString;
  }
  const day = String(timestamp.getDate()).padStart(2, '0');
  const month = timestamp.toLocaleString('default', { month: 'short' }).slice(0, 3);
  const year = timestamp.getFullYear();
  const formattedDate = `${day}-${month}-${year}`;
  return formattedDate;
};
const convertToIst = (dateString: string) =>{
  const istDate = new Date(dateString);
  const istOptions = { timeZone: 'Asia/Kolkata' };
  const istFormattedDate = istDate.toLocaleString('en-US', istOptions);

  return istFormattedDate
}

useEffect(() => {
  try{
    
    const messages: React.SetStateAction<any[]> = [];


  reviewHistory.map((msg, index) => {
      let columnNameText = '';
    let updatedValue = (msg.new_value === null || msg.new_value === "") ? 'None' : msg.new_value;
    let previousValue = msg.old_value

    switch (msg.column_name) {
      case 'start_date':
      case 'end_date':
        columnNameText = msg.column_name.replace('_', ' ');
        updatedValue = msg.new_value ? formatDate(msg.new_value) : null;
        previousValue = msg.old_value ? formatDate(msg.old_value) : null;
        break;
      case 'member_sysvine_role_id':
        columnNameText = 'sysvine role';
        break;
      case 'member_client_role_id':
        columnNameText = 'client role';
        break;
      case 'member_status_id':
        columnNameText = 'status'
        break;
      case 'member_cost_type_id':
        columnNameText = 'cost type';
        break;

      default:
        columnNameText = msg.column_name;

    }
    let updatedMessage = (previousValue === null || previousValue ==='')
    ? `${msg?.user?.userfullname} set the ${columnNameText} to "${updatedValue}" on ${parseDate(msg.created_at)}`
    : `${msg?.user?.userfullname} updated the ${columnNameText} from "${previousValue}" to "${updatedValue}" on ${parseDate(msg.created_at)}`;
      if (msg.column_name === 'start_date' && previousValue === null) {
      updatedMessage = `${msg?.user?.userfullname} set ${columnNameText} to "${updatedValue}" on ${parseDate(msg.created_at)}`;
      }
      messages.push({
      id: index + 1,
      message: updatedMessage
      });
    });
    const addedByMessage = `${
      props?.user?.userfullname
    } was added by ${createdBy} ${
      props?.created ? `on ${parseDate(convertToIst(props?.created))}` : ''
    }`;

    messages.push({
      id: messages.length + 1,
      message: addedByMessage,
    });
    // Update the state with the new messages
    setLogMessages(messages);

  }
catch(e){
  console.warn("Error Caught in forming Review History Contents", e)
}
}, [reviewHistory,props, createdBy])

  return (
    <OffCanvas
      setOpen={setUpcomingEventsEditOffcanvas}
      isOpen={upcomingEventsEditOffcanvas}
      onClose={handleOffCanvasClose}
      titleId="upcomingEdit"
      isBodyScroll
      placement="end"
    >
      <OffCanvasHeader setOpen={setUpcomingEventsEditOffcanvas}>
        <OffCanvasTitle id="...">
          {props.user?.userfullname ?? ""}
        </OffCanvasTitle>
      </OffCanvasHeader>
      <OffCanvasBody className="...">
      <Chat>
    <ChatGroup
      messages={logMessages}
      isReply={false}
      user={{
        src: undefined,
        srcSet: undefined,
        username: undefined,
        name: undefined,
        surname: undefined,
        isOnline: undefined,
        color: undefined,
      }}
      reviewHistory = {true}
    />
  </Chat>
            
      </OffCanvasBody>
      <div className='row m-0'>
					<div className='col-12 p-3'>
					</div>
				</div>
        {isLoading && (
          <div className="processvine-overlay-box">
            <Spinner
              className="text-center processvine-no-projects processvine-spinner"
              inButton
            />
          </div>
        )}
    </OffCanvas>
  );
};

export default ReviewHistory;