import { getValueFromToken, hrGroups, prvnGroups } from '../../App/Constants';
import axios from './AxiosService';
import Cookies from 'js-cookie';
import { serviceURL, serviceCGPURL } from '../../types';

export async function getLoggedInUser() {
	const emailAddress = getValueFromToken('email');
	const userGroups: any[] = [];
	const response = await axios.get(`${serviceURL}/api/progressvine/logged-user`, {
		params: {
			email: emailAddress
		},
	});
	response?.data?.memberShipGroups?.map((items: { authority: any; }) => {
		userGroups.push(items.authority);
	});
	const userDetails = {
		groups: userGroups, departmentId: response?.data?.departmentId || ''
	};
	const parsedUserDetails = JSON.stringify(userDetails);
	Cookies.set('userDetails', parsedUserDetails);

	return response;
}
