import React, { FC, useCallback, useEffect, useState } from "react";
import PageWrapper from "../../layout/PageWrapper/PageWrapper";
import Page from "../../layout/Page/Page";
import Card, {
  CardActions,
  CardBody,
  CardHeader,
  CardLabel,
  CardTitle,
} from "../../components/bootstrap/Card";

import Icon from "../../components/icon/Icon";

import ProjectMemberPopup from "./projectMemberPopup";
import axios from "axios";
import SubHeader, {
  SubHeaderLeft,
  SubHeaderRight,
} from "../../layout/SubHeader/SubHeader";
import Input from "../../components/bootstrap/forms/Input";
import Spinner from "../../components/bootstrap/Spinner";
import AddProjectMemberPopup from "./addprojectmemberpopup";
import { railsAPI } from "../../types";
import { getProjectMemberDetails, searchRoster } from "../../common/data/service";
import { projectMemberShipTitle, validateExpiredToken } from "../../App/Constants";
import { useNavigate } from "react-router-dom";
import classNames from "classnames";
import Button from "../../components/bootstrap/Button";
import PaginationButtons, { PER_COUNT, dataPagination } from "../../components/PaginationButtons";
import { dashboardMenu } from "../../menu";
import { useToasts } from "react-toast-notifications";
// This is a functional component named RosterTable.
const RosterTable: FC<any> = (props) => {
  // State variables using the useState hook.
  const [projMembersData, setProjMemebersData] = useState<any[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [modalStatus, setModalStatus] = useState(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(PER_COUNT['10']);
  const [onCurrentPageItems, setOnCurrentPageItems] = useState<any[]>([]);
  const [searchDatapresent, setSearchDataPresent] = useState(false);
  const projectId = props.projectId
  const selectedProjectName = localStorage.getItem('projectName');
  const [title, setTitle] = useState<any[]>([]);
  const navigate = useNavigate();
  const [searchPerPage, setSearchPerPage] = useState<number>(PER_COUNT['10']);
  const { addToast } = useToasts();
  useEffect(() => {
    // Initialize loading state.
    setIsLoading(true);

    // Fetch project member details using the getProjectMemberDetails function.
    getProjectMemberDetails({ projectId: projectId, projectName: selectedProjectName })
      .then((res: { data: { data: React.SetStateAction<any[]>; }; }) => {
        // Update state with the fetched data.
        setProjMemebersData(res?.data?.data);
        setTitle(res?.data?.data);
        // Disable loading state.
        setIsLoading(false);
      })
      .catch((e) => {
        if (!validateExpiredToken(e.message)) {
          navigate('/');
          window.location.reload();
        }
        console.log(e.message);
      });
  }, [projectId, navigate, selectedProjectName]);

  // Function to handle the search button click.
  const handleSearchClick = async (searchValue: any) => {
    setTableLoading(true)
    try {
      // Fetch search results using the searchRoster function.
      const response = await searchRoster({ projectID: projectId, searchValue: searchValue });
      // Update state with search results.
      if (response.data.length === 0) {
        setSearchDataPresent(true)
      }
      setProjMemebersData(response.data);
      setSearchPerPage(PER_COUNT['5']);
      setCurrentPage(1);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setTableLoading(false)
  };

  // Function to format a date string into a specific format.
  const formatDate = (dateString: string) => {
    const timestamp = new Date(dateString);
    const day = timestamp.getDate();
    const month = timestamp.toLocaleString('default', { month: 'short' }).slice(0, 3);
    const year = timestamp.getFullYear();
    const formattedDate = `${day}-${month}-${year}`;
    return formattedDate;
  };

  // Function to handle changes in the search input.
  const handleSearchChange = (e: any) => {
    e.target.value != "" ? setCloseBtn(true) : setCloseBtn(false);
    setSearchTerm(e.target.value);
    // Trigger search with the new search term.
    handleSearchClick(e.target.value);
  };
  const handleSearchClose = () => {
    setSearchTerm("");
    handleSearchClick("");
    setCloseBtn(false);
  };
  // More state variables.
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [tableLoading, setTableLoading] = useState<boolean>(false);
  const [projectFilterName, setProjectFilterName] = useState("");
  const [closeBtn, setCloseBtn] = useState(false);
  console.log(projMembersData)
  const projectName = title[title.length - 1]?.tm_project?.project_name || ''
  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };
  const itemsOnPage = dataPagination(projMembersData, currentPage, searchTerm ? searchPerPage : perPage);
  return (
    <PageWrapper className='prms-page-wrapper' title={projectMemberShipTitle}>
      <Page>
        <Card stretch data-tour="list">
          <CardHeader>
            <CardLabel>
              <CardTitle>{`SYVN - FND - ${projectName} - Team Roster Sheet (TRS)`}</CardTitle>
            </CardLabel>
            <CardActions>
            </CardActions>
          </CardHeader>
          <SubHeader className='div-text-box'>
            <SubHeaderLeft>
              <label
                className="border-0 bg-transparent cursor-pointer me-0"
                htmlFor="searchInput"
              >
                <Icon icon="Search" size="2x" color="primary" />
              </label>
              <Input
                id="searchInput"
                className="border-0 shadow-none bg-transparent"
                placeholder="Search Name, FND Level and Status"
                value={searchTerm || ""}
                onChange={(e: any) => {
                  handleSearchChange(e);
                }}
              />
              <Button
                className={classNames(
                  { 'processvine-btn-close': !closeBtn },
                  'btn-close'
                )}
                type='button'
                aria-label='Close'
                onClick={() => handleSearchClose()}
              />
            </SubHeaderLeft>
          </SubHeader>
          <CardBody
            className="table-responsive project-membership-table-header"
            isScrollable
          >
            <table
              className={`table table-modern table-hover processvine-table ${tableLoading ? 'height-100' : ''}`}
              style={{ minWidth: "100%", width: "auto" }}
            >              <thead>
                <tr>
                  <th className="cursor-pointer prms-table-header" scope="col">
                    Name
                    <Icon size="lg" />
                  </th>
                  <th scope="col" className="cursor-pointer prms-table-header">
                    Status
                    <Icon size="lg" />
                  </th>
                  <th scope="col" className="cursor-pointer prms-table-header">
                    Start Date
                    <Icon size="lg" />
                  </th>
                  <th scope="col" className="cursor-pointer prms-table-header" style={{ whiteSpace: "nowrap" }} >
                    End Date
                    <Icon size="lg" />
                  </th>
                  <th scope="col" className="cursor-pointer prms-table-header">
                    Location
                    <Icon size="lg" />
                  </th>
                  <th scope="col" className="cursor-pointer prms-table-header">
                    FND Level
                    <Icon size="lg" />
                  </th>
                  <th scope="col" className="cursor-pointer prms-table-header">
                    Cost Level
                    <Icon size="lg" />
                  </th>
                  <th scope="col" className="cursor-pointer prms-table-header" >
                    Comments
                    <Icon size="lg" />
                  </th>
                </tr>
              </thead>

              <tbody className={tableLoading ? 'blurred' : ''}>
                {tableLoading ? (
                  <tr>
                    <td className="text-center processvine-spinner">
                      <Spinner inButton />
                    </td>
                  </tr>
          )  : (
                  projMembersData.length > 0 ?
                    <>
                      {itemsOnPage.map((row) => (
                        <tr key={row.id}>
                          <td>{row.user ? row.user.userfullname : ""}</td>
                          <td>{row.status ? row.status.name : "-"}</td>
                          <td className='center-align-table-data'>{row.start_date ? formatDate(row.start_date) : "-"}</td>
                          <td className='center-align-table-data'>{row.end_date ? formatDate(row.end_date) : "-"}</td>

                          <td>{row.user.business_unit ? row.user.business_unit.unitname : "-"}</td>
                          <td>{row.client_role ? row.client_role.name : "-"}</td>
                          <td>{row.cost_type ? row.cost_type.name : "-"}</td>
                          <td>{row.description ? row.description : "-"}</td>
                        </tr>
                      ))}
                    </>
                    : (
                      isLoading ? null : (searchDatapresent ? (<div className='text-center processvine-no-projects'>
                        No matches found for the searched criteria

                      </div>) : (
                        <div className='text-center processvine-no-projects'>
                          No Members are Mapped to this Project
                        </div>)
                      )
                    )
                )}
              </tbody>
            </table>

            {(isLoading || props.load) && (
              <div className="processvine-overlay-box">
                <Spinner
                  className="text-center processvine-no-projects processvine-spinner"
                  inButton
                />
              </div>
            )}
          </CardBody>
          <PaginationButtons
            setCurrentPage={handlePageChange}
            currentPage={currentPage}
            perPage={perPage}
            setPerPage={setPerPage}
            data={projMembersData}
            label="Members"
          />
        </Card>
      </Page>
    </PageWrapper>
  );
};

// Export the RosterTable component as the default export.
export default RosterTable;