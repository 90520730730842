import React, { useState, useEffect } from 'react';
import useDarkMode from '../../hooks/useDarkMode';
import Button from '../../components/bootstrap/Button';
import classNames from 'classnames';
import { FormikValues, useFormik } from 'formik';
import Modal, {
    ModalBody,
    ModalHeader,
    ModalTitle,
    ModalFooter,
} from '../../components/bootstrap/Modal';
import Input from '../../components/bootstrap/forms/Input';
import { getSelectedProjectDetails, updateProjectDetail } from '../../common/data/service';
import FormGroup from '../../components/bootstrap/forms/FormGroup';
import Popovers from '../../components/bootstrap/Popovers';
import { UrlList } from './types';
import Icon from "../../components/icon/Icon";
import Alert, { AlertHeading } from '../../components/bootstrap/Alert';
import { validateExpiredToken } from '../../App/Constants';
import { useNavigate } from 'react-router-dom';

const UrlTab = (props: any) => {
    const { projectId, urlDetails, setUrlDetails, setIsLoading, aedConstant, aedAccess } = props;
    const urlLimit = aedConstant?.URL_LIMIT || 0;
    const { darkModeStatus } = useDarkMode();
    const [openURLModal, setOpenUrlModal] = useState('');
    const [deleteUrlId, setDeleteUrlId] = useState('');
    const [deleteConfirmationPopup, setDeleteConfirmationPopup] = useState<boolean>(false);
    const [editurl, setEditUrl] = useState(false);
    const [addUrl, setAddUrl] = useState(false);
    const [expandedRows, setExpandedRows] = useState<string[]>([]);
    const [editIndex, setEditIndex] = useState('');
    const [isUrlsEdit, setIsUrlsEdit] = useState(false);
    const [alert, setAlert] = useState<boolean>(false);
    const [alertMessage, setAlertMessage] = useState<string>("");
    const navigate = useNavigate();
    const toggleRowExpand = (index: string) => {
        if (expandedRows.includes(index)) {
            setExpandedRows(expandedRows.filter((rowIndex) => rowIndex !== index));
        } else {
            setExpandedRows([index]);
        }
    };
    const formik = useFormik({
        onSubmit: (values) => {
            const isValid = !(values.urlName === '' || values.url === '');

            if (isValid) {
                setIsLoading(true);
                updateProjectDetail({
                    projectId: projectId,
                    aedUrls: values.aedUrlJson,
                }).catch((e) => {
                    if (!validateExpiredToken(e.message)) {
                      navigate('/');
                      window.location.reload();
                    }
                  });
                handleClose();
                setData();
            }
        },
        initialValues: {
            aedUrlJson: urlDetails,
            urlName: '',
            url: '',
        },
        validate: (values) => {
            const errors: Partial<FormikValues> = {};
            const isDuplicateTitle = urlDetails?.urls?.some((url_list: { title: string; }, index: string) => {
                if (editurl) {
                    return index !== editIndex && url_list.title.toLowerCase()  === values.urlName.toLowerCase()
                } else {
                    return url_list.title.toLowerCase()  === values.urlName.toLowerCase()
                }
              });
              const isDuplicateUrl = urlDetails?.urls?.some((url_list: { link: string; }, index: string) => {
                if (editurl) {
                    return index !== editIndex && url_list.link === values.url
                } else {
                    return url_list.link === values.url
                }
              });
            if (!values.urlName) {
                errors.urlName = 'Title is required';
            } else if (isDuplicateTitle) {
                errors.urlName = 'Title must be unique';
            }
            if (!values.url) {
                errors.url = 'Url is required';
            } else if (!isValisUrl(values.url)) {
                errors.url = 'Please enter valid URL';
            } else if (isDuplicateUrl) {
                errors.url = 'URL must be unique';
            }

            return errors;
        },
    });

    const handleDelete = (index: string) => {
        delete urlDetails.urls[index];
        updateProjectDetail({
            projectId: projectId,
            aedUrls: urlDetails,
        });
        setAlertMessage('Deleted Successfully');
    };
    const handleClose = () => {
        setOpenUrlModal('');
        if (isUrlsEdit) {
            setIsUrlsEdit(false);
        }
        setAddUrl(false);
        formik.resetForm();
    };
    const setData = async () => {
        await getSelectedProjectDetails({ projectId }).then((res) => {
            setUrlDetails(res?.data?.data[0]?.aed_urls_json);
            setIsLoading(false);
        });
        setAlert(true);
        setTimeout(() => {
           setAlert(false);
        }, 3000)
    };
    const isValisUrl = (str: string | URL) => {
        try {
            const newUrl = new URL(str);
            return newUrl.protocol === 'http:' || newUrl.protocol === 'https:';
        } catch (err) {
            return false;
        }
    };
    const handleSave = () => {
        const newRole = { title: formik.values.urlName, link: formik.values.url };
        const updatedRoles = urlDetails.urls ? [...urlDetails.urls, newRole] : [newRole];
        const aedRole = { urls: updatedRoles };
        formik.setFieldValue('aedUrlJson', aedRole);
        setAlertMessage('Saved Successfully');
    };
    const handleEdit = () => {
        const updatedUrls = [...urlDetails.urls];
        updatedUrls[parseInt(editIndex)] = { title: formik.values.urlName, link: formik.values.url };
        const aedUrls = { urls: updatedUrls };
        formik.setFieldValue("aedUrlJson", aedUrls);
        setAlertMessage('Updated Successfully')
    };

    useEffect(() => {
        (addUrl || deleteConfirmationPopup) ? document.body.style.overflow = 'hidden' : document.body.style.overflow = 'unset';
      }, [addUrl, deleteConfirmationPopup])
    return (
        <div>
            <div className='card'>
                <div className='card-header'>
                    <div className="row g-3 align-items-center">
                        <div className="col d-flex align-items-center">
                            <div className="flex-shrink-0">
                                <div className="ratio ratio-1x1 aed-icon-box">
                                    <div className={classNames("rounded-2 d-flex align-items-center justify-content-center", darkModeStatus ? "bg-lo25-primary" : "bg-l10-primary")}>
                                        <label
                                            className="border-0 bg-transparent me-0"
                                            htmlFor="Link">
                                            <Icon icon="Link" size="2x" color='primary' />
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className="flex-grow-1 ms-3 d-flex justify-content-between align-items-center">
                                <div>
                                    <div className="fw-bold fs-6 mb-0">
                                    <h5 className="card-title">Urls</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='card-actions'>
                        {!editurl && aedAccess?.edit && (
                            <Button
                                icon='Plus'
                                color="info"
                                onClick={() => {
                                    setAddUrl(true);
                                }}>
                                Add
                            </Button>
                        )}
                        {aedAccess?.edit && <Button
                            isOutline={!darkModeStatus}
                            color={'dark'}
                            isLight={darkModeStatus}
                            className={classNames('text-nowrap', {
                                'border-light': !darkModeStatus,
                            })}
                            onClick={() => {
                                setEditUrl(!editurl);
                            }}
                            icon={editurl ? 'Close' : 'Edit'}
                            isDisable={!editurl && (!urlDetails || !urlDetails.urls || urlDetails.urls.length === 0)}
                            ></Button>
                        }
                    </div>
                </div>

                <div className='card-body'>
                    <div className="aed-card">
                        {urlDetails && urlDetails.urls && urlDetails.urls.length > 0 && (
                            <table className='table table-modern table-hover'>
                                <tbody>
                                    {urlDetails.urls.map((url_list: UrlList, index: string) => (
                                        <tr key={index}>
                                            <td className="col-lg-3">
                                                <div className="aed-table">
                                                    {url_list.title}
                                                </div>
                                            </td>
                                            <td className="col-lg-7">
                                                <div
                                                    className={`cell-content ${
                                                        expandedRows.includes(index) ? 'expanded' : ''
                                                    }`}>
                                                    {url_list.link.length > urlLimit &&
                                                    !expandedRows.includes(index) ? (
                                                        <>
                                                            <a
                                                                href={url_list.link}
                                                                target='_blank'
                                                                rel='noopener noreferrer'>
                                                                {url_list.link.slice(0, urlLimit)}
                                                            </a>{' '}
                                                            <span
                                                                className='read-more'
                                                                onClick={() => toggleRowExpand(index)}>
                                                                {'...Read more'}
                                                            </span>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <a
                                                                href={url_list.link}
                                                                target='_blank'
                                                                rel='noopener noreferrer'>
                                                                {url_list.link}
                                                            </a>
                                                            {url_list.link.length > urlLimit &&
                                                                expandedRows.includes(index) && (
                                                                    <span
                                                                        className='read-more'
                                                                        onClick={() =>
                                                                            toggleRowExpand(index)
                                                                        }>
                                                                        {'...Read less'}
                                                                    </span>
                                                                )}
                                                        </>
                                                    )}
                                                </div>
                                            </td>

                                            <td className='center-align-table-data col-lg-2'>
                                                {editurl && (
                                                    <div className='col-lg'>
                                                        <Popovers trigger='hover' desc='Delete'>
                                                            <Button
                                                                isOutline={!darkModeStatus}
                                                                color='dark'
                                                                isLight={darkModeStatus}
                                                                className={classNames('text-nowrap', {
                                                                    'border-light': !darkModeStatus,
                                                                })}
                                                                icon='Delete'
                                                                onClick={() => {
                                                                    setDeleteUrlId(index);
                                                                    setDeleteConfirmationPopup(true);
                                                                }}></Button>
                                                        </Popovers>
                                                        <Popovers trigger='hover' desc='Edit'>
                                                            <Button
                                                                isOutline={!darkModeStatus}
                                                                color='dark'
                                                                isLight={darkModeStatus}
                                                                className={classNames('text-nowrap', {
                                                                    'border-light': !darkModeStatus,
                                                                })}
                                                                icon='Edit'
                                                                onClick={() => {
                                                                    formik.setFieldValue(
                                                                        'urlName',
                                                                        url_list.title,
                                                                    );
                                                                    formik.setFieldValue(
                                                                        'url',
                                                                        url_list.link,
                                                                    );
                                                                    setAddUrl(true);
                                                                    setOpenUrlModal('Edit');
                                                                    setEditIndex(index);
                                                                    setIsUrlsEdit(true);
                                                                }}></Button>
                                                        </Popovers>
                                                    </div>
                                                )}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        )}
                    </div>
                </div>
                <Modal
                    isStaticBackdrop={true}
                    setIsOpen={setAddUrl}
                    isOpen={addUrl}
                    size='lg'
                    isCentered>
                    <ModalHeader setIsOpen={setAddUrl}>
                        <ModalTitle id='urlModal'>
                            <h5>{openURLModal === 'Edit' ? 'Edit URL' : 'Add URL'}</h5>
                        </ModalTitle>
                        <Button
                            color= {darkModeStatus ? 'dark' : 'light'}
                            onClick={() => handleClose()}
                            className={darkModeStatus ? 'modal-close-btn-dark' : 'modal-close-btn'}
                            icon='Close'></Button>
                    </ModalHeader>
                    <ModalBody>
                        <div className='row g-3'>
                            <div className='col-12 p-2'>
                                <FormGroup id='urlName' label='Title' isRequired={true} labelClassName="label-font-color">
                                    <Input
                                        id='urlName'
                                        type='text'
                                        className='form-control processvine-form-font-weight'
                                        value={formik.values.urlName}
                                        onBlur={formik.handleBlur}
                                        isValid={formik.isValid && formik.touched.urlName}
                                        isTouched={formik.touched.urlName}
                                        invalidFeedback={formik.errors.urlName}
                                        onChange={formik.handleChange}
                                    />
                                </FormGroup>
                            </div>
                            <div className='col-12 p-2'>
                                <FormGroup id='url' label='Url' isRequired={true} labelClassName="label-font-color">
                                    <Input
                                        id='url'
                                        type='text'
                                        className='form-control processvine-form-font-weight'
                                        onChange={formik.handleChange}
                                        value={formik.values.url}
                                        onBlur={formik.handleBlur}
                                        isValid={formik.isValid && formik.touched.url}
                                        isTouched={formik.touched.url}
                                        invalidFeedback={formik.errors.url}
                                    />
                                </FormGroup>
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color='info'
                            onClick={() => {
                                isUrlsEdit ? handleEdit() : handleSave();
                                formik.handleSubmit();
                            }}>
                            Save
                        </Button>
                    </ModalFooter>
                </Modal>
            </div>
            <Modal
                setIsOpen={setDeleteConfirmationPopup}
                isOpen={deleteConfirmationPopup}
                isStaticBackdrop={true}
                titleId='Delete Confirmation'>
                <ModalHeader setIsOpen={setDeleteConfirmationPopup}>
                    <ModalTitle id='new-todo-modal'>Delete Confirmation</ModalTitle>
                </ModalHeader>
                <ModalBody>Are you sure you want to delete?</ModalBody>
                <ModalFooter>
                    <Button
                        color='danger'
                        onClick={() => {
                            setIsLoading(true);
                            handleDelete(deleteUrlId);
                            setDeleteConfirmationPopup(false);
                            setData();
                        }}>
                        Delete
                    </Button>
                    &nbsp;
                    <Button color='success' onClick={() => setDeleteConfirmationPopup(false)}>
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>
            {
                alert &&
                <Alert
                    icon=''
                    isLight
                    color='primary'
                    borderWidth={0}
                    className='cgp-container-alert'
                    >
                    <AlertHeading tag='h2' className={classNames('text-sm cgp-container-alertHeading')}>
                        {alertMessage}
                    </AlertHeading>
                </Alert>
      }
        </div>
    );
};

export default UrlTab;
