import * as React from 'react';

function DashboardIcons(props) {
	return (
            <svg 
                viewBox='25 14 22 28'
                xmlns="http://www.w3.org/2000/svg"
                width='1em'
                height='1em'
                {...props}>
                    <text x="25" y="35" stroke="white" fill="white" stroke-width="0.5">P</text>
            </svg>
        
	);
}

export default DashboardIcons;
