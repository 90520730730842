import React, { FC, useCallback, useMemo, useState } from 'react';
import { SubHeaderRight } from '../../layout/SubHeader/SubHeader';
import Dropdown, { DropdownMenu, DropdownToggle } from '../../components/bootstrap/Dropdown';
import Button from '../../components/bootstrap/Button';
import Popovers from '../../components/bootstrap/Popovers';
import FormGroup from '../../components/bootstrap/forms/FormGroup';
import { ChecksGroup } from '../../components/bootstrap/forms/Checks';
import Multiselect from 'multiselect-react-dropdown';
import { Type } from '../types/index';

interface Props {
	projectFilterName: string;
	statusFilterValue: Type.ProjectStatus[];
	typeFilterValue: Type.ISelect[];
	ProjectStatus: Type.ProjectStatus[];
	ProjectType: Type.ISelect[];
	handleApplyFilter: () => void;
	handleClearFilter: () => void;
	handleSelectStatus: (selectedList: Type.ProjectStatus[]) => void;
	handleSelectType: (selectedList: Type.ISelect[]) => void;
}

export const ProjectFilter: FC<Props> = (props) => {
	const {
		projectFilterName,
		statusFilterValue,
		typeFilterValue,
		ProjectStatus,
		ProjectType,
		handleApplyFilter,
		handleClearFilter,
		handleSelectStatus,
		handleSelectType,
	} = props;
	const [isOpen, setIsOpen] = useState<boolean>(false);

	return (
		<>
			<SubHeaderRight>
				<Dropdown isOpen={isOpen} setIsOpen={setIsOpen}>
					<DropdownToggle hasIcon={false}>
						<Button
							icon='FilterAlt'
							color='info'
							isLight
							className='btn-only-icon position-relative'>
							{!(projectFilterName == '') && (
								<Popovers desc='Filtering applied' trigger='hover'>
									<span className='position-absolute top-0 start-100 translate-middle badge border border-light rounded-circle bg-danger p-2'>
										<span className='visually-hidden'>there is filtering</span>
									</span>
								</Popovers>
							)}
						</Button>
					</DropdownToggle>
					<DropdownMenu isAlignmentEnd size='lg' isCloseAfterLeave={false}>
						<div className='container py-2'>
							<div className='row g-3'>
								<FormGroup label='Project Status' className='col-12'>
									<ChecksGroup>
										<Multiselect
											className='processvine-multiSelect-optionContainer'
											options={ProjectStatus}
											isObject={true}
											displayValue='value'
											selectedValues={statusFilterValue}
											onSelect={handleSelectStatus}
											onRemove={handleSelectStatus}
											placeholder='Select Project Status'
										/>
									</ChecksGroup>
								</FormGroup>
								<FormGroup label='Project Type' className='col-12'>
									<ChecksGroup>
										<Multiselect
											className='processvine-multiSelect-optionContainer'
											options={ProjectType}
											isObject={true}
											displayValue='value'
											selectedValues={typeFilterValue}
											onSelect={handleSelectType}
											onRemove={handleSelectType}
											placeholder='Select Project Type'
										/>
									</ChecksGroup>
								</FormGroup>
								<div className='col-6'>
									<Button
										className='btn btn-outline-info btn-hover-shadow-lg shadow-none w-100'
										onClick={() => {
											handleApplyFilter();
											setIsOpen(false);
										}}>
										Apply
									</Button>
								</div>
								{statusFilterValue.length != 0 || typeFilterValue.length != 0 ? (
									<div className='col-6'>
										<Button
											className='btn btn-outline-info btn-hover-shadow-lg shadow-none w-100'
											onClick={() => {
												handleClearFilter();
												setIsOpen(false);
											}}>
											Clear
										</Button>
									</div>
								) : null}
							</div>
						</div>
					</DropdownMenu>
				</Dropdown>
			</SubHeaderRight>
		</>
	);
};
