import React, { FC, useEffect, useState } from "react";
import ProjectMemberPopup from "./projectMemberPopup";
import Spinner from "../../components/bootstrap/Spinner";
import { getUserGroups } from "../../common/data/service";
import { validateExpiredToken } from "../../App/Constants";

const ProjectMemberPopupBridge: FC<any> = (props) => {
    const [isLoadingUserGroups, setIsLoadingUserGroups] = useState(false);
    const [displayNameArray, setDisplayNameArray] = useState([]);
    const [groupOptions, setGroupOptions] = useState([]);

    useEffect(() => {
        setIsLoadingUserGroups(true);
        getUserGroups({ projectId: props.projectId, userId: props.emp_id })
            .then((res) => {
                const fetchedGroupOptions = res?.data?.groups.map((group: { displayName: any; name: any; }) => ({
                    label: group.displayName,
                    value: group.name,
                }));

                const displayNames = fetchedGroupOptions.map((groupOption: { label: any; }) => groupOption.label);
                setGroupOptions(fetchedGroupOptions);
                setDisplayNameArray(displayNames);
            })
            .catch((e: { message: string; }) => {
                if (!validateExpiredToken(e.message)) {
                    window.location.reload();
                }
            }).finally(() => {
                setIsLoadingUserGroups(false);
            });
    }, [props.projectId, props.emp_id]);
    return isLoadingUserGroups ? (
        <div className="processvine-overlay-box">
            <Spinner
                className="text-center processvine-no-projects processvine-spinner"
                inButton
            />
        </div>
    ) : (
        <ProjectMemberPopup {...props} groupOptions={groupOptions} displayNameArray={displayNameArray} isLoadingUserGroups={isLoadingUserGroups} />
    );
};
export default ProjectMemberPopupBridge;