import * as React from 'react';

function DashboardCgpIcons(props) {
	return (
        <svg 
            viewBox='35 14 22 28'
            xmlns="http://www.w3.org/2000/svg"
            width='1.5em'
            height='1em'
            {...props}>
                <text x="25" y="35" stroke="white" fill="white" stroke-width="0.5">CGP</text>
        </svg>
    
);
}

export default DashboardCgpIcons;