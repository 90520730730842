import * as React from 'react';

function HrProbationersIcons(props) {
	return (
                <svg
                xmlns='http://www.w3.org/2000/svg'
                height='1em'
                viewBox='0 0 24 24'
                width='1em'
                className='svg-icon'
                {...props}>
        <path d="M12.8802 13.1895C16.4479 13.1895 19.3402 10.2973 19.3402 6.72953C19.3402 3.16177 16.4479 0.269531 12.8802 0.269531C9.31241 0.269531 6.42017 3.16177 6.42017 6.72953C6.42017 10.2973 9.31241 13.1895 12.8802 13.1895Z" fill="white"/>
        <path d="M18 25.5C18 24.1193 19.1193 23 20.5 23V23C21.8807 23 23 24.1193 23 25.5V25.5C23 26.8807 21.8807 28 20.5 28V28C19.1193 28 18 26.8807 18 25.5V25.5Z" fill="white"/>
        <path d="M16 30.75C16 30.3358 16.3358 30 16.75 30H24.25C24.6642 30 25 30.3358 25 30.75V30.75C25 31.1642 24.6642 31.5 24.25 31.5H16.75C16.3358 31.5 16 31.1642 16 30.75V30.75Z" fill="white"/>
        <path d="M11.0202 29.0002V34.0002C11.0202 34.2655 11.1255 34.5198 11.3131 34.7073C11.5006 34.8949 11.755 35.0002 12.0202 35.0002H29.0202C29.2854 35.0002 29.5397 34.8949 29.7273 34.7073C29.9148 34.5198 30.0202 34.2655 30.0202 34.0002V20.0002C30.0202 19.735 29.9148 19.4807 29.7273 19.2931C29.5397 19.1056 29.2854 19.0002 29.0202 19.0002H22.0202V17.5302C22.0202 17.265 21.9148 17.0107 21.7273 16.8231C21.5397 16.6356 21.2854 16.5302 21.0202 16.5302C20.755 16.5302 20.5006 16.6356 20.3131 16.8231C20.1255 17.0107 20.0202 17.265 20.0202 17.5302V19.0002H18.0202V15.4202C16.3209 15.1427 14.602 15.0022 12.8802 15.0002C9.08297 14.9841 5.32832 15.7999 1.88017 17.3902C1.31266 17.6581 0.83388 18.083 0.50065 18.6148C0.167419 19.1466 -0.0062759 19.7627 0.000173279 20.3902V29.0002H11.0202ZM28.0202 33.0002H13.0202V21.0002H20.0202V21.4202C20.0202 21.6855 20.1255 21.9398 20.3131 22.1273C20.5006 22.3149 20.755 22.4202 21.0202 22.4202C21.2854 22.4202 21.5397 22.3149 21.7273 22.1273C21.9148 21.9398 22.0202 21.6855 22.0202 21.4202V21.0002H28.0202V33.0002Z" fill="white"/>
        </svg>
        
	);
}

export default HrProbationersIcons;
