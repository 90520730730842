import React, { createContext, useState, useContext, ReactNode, SetStateAction, Dispatch } from 'react';
import { getGroupFromCookie } from '../App/Constants';
import { leadGroups, riskCount, riskReviewDate } from '../rkvn/util';
import { getRiskDashboard } from '../common/data/service';
import { UserDt } from '../model/Risk';
import * as Util from '../rkvn/util';

interface RiskContext {
  FetchRiskLists: () => void;
  userLists: UserDt[];
  setUserLists: React.Dispatch<React.SetStateAction<UserDt[]>>;
}
// Create a context
const RiskContext = createContext<RiskContext | undefined>(undefined);
// Create a provider component
export const RiskProvider = ({ children }: { children: ReactNode }) => {
  const [userLists, setUserLists] = useState<UserDt[]>([]);
  const groupResponse = getGroupFromCookie();
  const leadTeam = leadGroups(groupResponse);
  let validate = Util.validateRKVNMenu(groupResponse);

  const FetchRiskLists = async () => {
    const groups = leadGroups(groupResponse);
    try {
      if(leadTeam.length > 0)
        await getRiskDashboard(validate.admin, JSON.stringify(groups))
          .then((res) => {
            const dataset = JSON.parse(res?.data.feature);
            if (dataset != null && dataset.length > 0) {
              riskCount(dataset);
            }
          })
          .catch((e) => {
            console.log(e);
          });
      }
      catch (error) {
        console.error('Error fetching data:', error);
      }
  };


  return (
    <RiskContext.Provider value={{ FetchRiskLists, userLists, setUserLists }}>
      {children}
    </RiskContext.Provider>
  );
};


// Custom hook to use the RiskContext
export const useRiskContext = (): RiskContext => {
  const context = useContext(RiskContext);
  if (!context) {
    throw new Error('useRiskContext must be used within a RiskProvider');
  }
  return context;
};
