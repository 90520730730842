import React, { useEffect, useLayoutEffect, useRef, useContext } from 'react';
import { Route, Routes, useNavigate, useSearchParams } from 'react-router-dom';
import { Portal } from 'semantic-ui-react';
import { TourProvider } from '@reactour/tour';
import { ThemeProvider } from 'react-jss';
import { ReactNotifications } from 'react-notifications-component';
import { DefaultToastContainer, ToastProvider } from 'react-toast-notifications';
import { useFullscreen } from 'react-use';
import COLORS from '../common/data/enumColors';
import ThemeContext from '../contexts/themeContext';
import useDarkMode from '../hooks/useDarkMode';
import Aside from '../layout/Aside/Aside';
import Wrapper from '../layout/Wrapper/Wrapper';
import { demoPages, layoutMenu } from '../menu';
import steps, { styles } from '../steps';
import Alert, { AlertHeading } from '../components/bootstrap/Alert';
import { ClientID } from '../types';
import { getLoggedInUser } from '../common/data/commonService';
import Cookies from 'js-cookie';
import useCookie from '../hooks/useCookie';
import { AuthContext } from '../contexts/authContext';
import { signInToGoogleWithRedirect } from '../auth/auth.utils';
import { RiskProvider } from '../contexts/riskContext';


const App = () => {
    const [idToken, setIdToken] = useCookie('idToken');
    const navigate = useNavigate();

    const [searchParams, setSearchParams] = useSearchParams();

    /**
       * Dark Mode
       */
    const { themeStatus, darkModeStatus, setDarkModeStatus } = useDarkMode();
    const theme = {
        theme: themeStatus,
        primary: COLORS.PRIMARY.code,
        secondary: COLORS.SECONDARY.code,
        success: COLORS.SUCCESS.code,
        info: COLORS.INFO.code,
        warning: COLORS.WARNING.code,
        danger: COLORS.DANGER.code,
        dark: COLORS.DARK.code,
        light: COLORS.LIGHT.code,
    };

    useEffect(() => {
        if (darkModeStatus) {
            document.documentElement.setAttribute('theme', 'dark');
        }
        return () => {
            document.documentElement.removeAttribute('theme');
        };
    }, [darkModeStatus]);

    /**
     * Full Screen
     */
    // @ts-ignore
    const { fullScreenStatus, setFullScreenStatus } = useContext(ThemeContext);
    const ref = useRef(null);
    useFullscreen(ref, fullScreenStatus, {
        onClose: () => setFullScreenStatus(false),
    });

    function handleSignOut() {
        setIdToken('');
        Cookies.set('userDetails', '');
        setDarkModeStatus(false)
        navigate('/');
    }

    /**
     * Modern Design
     */
    useLayoutEffect(() => {
        if (process.env.REACT_APP_MODERN_DESGIN === 'true') {
            document.body.classList.add('modern-design');
        } else {
            document.body.classList.remove('modern-design');
        }
    });
    useEffect(() => {
        const idTokenParam = searchParams.get("idToken");

        if (idTokenParam !== null) {
            setIdToken(idTokenParam);
        }

        getLoggedInUser().then((res) => {
            let groupsArr = [];
            res?.data?.memberShipGroups?.map((items) => {
                groupsArr.push(items.authority);
            });
            Cookies.set('groups', JSON.stringify(groupsArr));
            if (idTokenParam !== null) {
                setSearchParams({});
            }
        }).catch(err => {
            console.error(err)
            handleSignOut();
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //  Add paths to the array that you don't want to be "Aside".
    const withOutAsidePages = [demoPages.login.path, demoPages.signUp.path, layoutMenu.blank.path];

    useEffect(() => {
        /* global google */
        if (google != undefined) {
            google?.accounts?.id?.initialize({
                client_id: ClientID,
                callback: (response) => {
                    setIdToken(response.credential);
                },
            });
            google?.accounts?.id?.renderButton(document.getElementById("signInDiv"), {
                theam: "dark",
                size: "large",
            });
        }
    }, [idToken, setIdToken]);


    function handleGoogleSignIn() {
        signInToGoogleWithRedirect();
    }

    return (
        <AuthContext.Provider value={{ idToken, setIdToken }}>
            <ThemeProvider theme={theme}>
                <ToastProvider components={{ ToastContainer: (props) => <div style={{ zIndex: 99999999 }}><DefaultToastContainer {...props} /></div> }}>
                    <RiskProvider>
                        <TourProvider
                            steps={steps}
                            styles={styles}
                            showNavigation={false}
                            showBadge={false}>
                            {idToken != undefined && idToken !== "" ? (
                                <div
                                    ref={ref}
                                    className='app'
                                    style={{
                                        backgroundColor: fullScreenStatus ? 'var(--bs-body-bg)' : undefined,
                                        zIndex: fullScreenStatus ? 1 : undefined,
                                        overflow: fullScreenStatus ? 'scroll' : undefined,
                                    }}>
                                    <><>
                                        <Routes>
                                            {withOutAsidePages.map((path) => (
                                                <Route key={path} path={path} />
                                            ))}
                                            <Route path='*' element={<Aside logout={() => handleSignOut()} />} />  {/* side menu componenets */}
                                        </Routes>
                                        <Wrapper />
                                    </></>

                                </div>
                            ) : (
                                <div className='navbar-container-login-page'>
                                    <Alert
                                        icon=''
                                        isLight
                                        color='primary'
                                        borderWidth={0}
                                        className='navbar-container-welcome-page'
                                    >
                                        <AlertHeading tag='h1' className='text-3xl'>
                                            Welcome to CorpVine 🎉
                                        </AlertHeading>
                                        {/* <span>You have reached your monthly sales targets.</span> */}
                                    </Alert>
                                    {/* <Button to="/" className='navbar-container-log-in btn btn-info' onClick={() => handleSignOut()}>Log In</Button> */}
                                </div>
                            )}
                            {
                                idToken != "" && idToken != undefined
                                    ?
                                    null
                                    :
                                    <div className="app-log-in btn btn-info">
                                        <div id='signInDiv'></div>
                                        {/*
                                        The purpose of the following div is to wrap around the existing Google button widget and prevent clicking on it. The click will be handled differently.
                                        The reason to do this is, now we don't want to do OAuth in front channel. Moved it to back channel and Google's GSI JS widget is not supporting it.
                                        If we remove this widget, we need to design the button. It'll not be personalized with name and DP. So using GSI only to display personalized button, but clicking it does
                                        different action than used to.
                                    */}
                                        <div onClick={handleGoogleSignIn} className='app-log-in-transparent-cover'></div>
                                    </div>
                            }
                            <Portal id='portal-notification'>
                                <ReactNotifications />
                            </Portal>
                        </TourProvider>
                    </RiskProvider>
                </ToastProvider>
            </ThemeProvider>
        </AuthContext.Provider>
    );
};

export default App;