import moment from 'moment';
import { formatedDate } from '../CGP/CustomControls';
import { getGoogleMembersList } from '../common/data/service';
const rkvnMenuAllowedList = ["LEADS", "DL", "DH", "CRM"]
export const AdminGroup = "SYVN.RKVN.ADMIN";
export const options = ['Open', 'Closed'];
export const currentDateUTC = new Date().toISOString();

export const DefaultDateUpdate = (dateValue:any) => {
  if (dateValue === '0001-01-01T00:00:00' || dateValue === '0000-00-00 00:00:00') {
    dateValue = currentDateUTC;
    dateValue = currentDateUTC;
  } 
  return dateValue;
}

export const parseDate = (date: any, timezone: string = 'UTC') => {
  if (!date) return ' - ';

  // Parse the date as UTC first
  const utcMoment = moment.utc(date); // Treat the input date as UTC

  // Convert to the specified timezone (or keep as UTC if 'UTC' is passed)
  const targetMoment = utcMoment.tz(timezone);

  // Format the date to 12-hour format with AM/PM
  const formattedDate = utcMoment.format('DD-MMM-YYYY HH:mm').toUpperCase();

  // Get relative time from now

  const relativeTime = targetMoment.fromNow();


  return `${formattedDate} - ${relativeTime}`;
};

// Status Labels for RiskVine 

export const LABEL: any = {

  LBL_AT_LG: "Audit Log",
  LBL_RW_LG: "All Reviews",

  MSG_FIELDS: "Please fill the required fields",
  MSG_HL_ERR: "Please check the highlighted error(s)",
  MSG_NO_DATA: "There are no changes in Data!",
  MSG_REQ: "Risk created successfully",
  MSG_UPD: "Risk updated successfully",
  MSG_REW_UPD: "Risk Reviewed successfully",
  MSG_REW_CLS: "Risk Closed successfully",
};

export const riskReviewDate = (ReviewDate: any, Status: any, isDateValue: boolean = false) => {
  let currentDate = new Date();
  let reviewDate = new Date(ReviewDate);
  if(Status == "Closed")
    return "REVIEWED";
  let value = formatedDate(ReviewDate, false);
  if (!isDateValue)
    value = "REVIEWED"
  if (currentDate > reviewDate)
    value = "PAST DUE";
  if (Status === "New")
    value = "NOT REVIEWED";
  return value;
}

export const riskCount = (dataset: any) => {
  let count = 0;

  for (var i = 0; i < dataset.length; i++) {
    const pastDueRisk = riskReviewDate(dataset[i].ReviewDate, dataset[i].Status, true)
    if (pastDueRisk === "PAST DUE" || pastDueRisk === "NOT REVIEWED") {
      count = count + 1;
    }
  }
  return count;
}
export const riskDataSet = (dataset: any, admin : string) => {
  let count = 0;
  let openRisks : any[] = [];
  let closedRisks : any[] = [];
  let result: any = {
    openRisks: openRisks,
    closedRisks: closedRisks,
    count: 0
  }
  for (var i = 0; i < dataset.length; i++) {
    dataset[i].DaysOpen = DateDiffValue(new Date(dataset[i].SubmissionDate)).toString();
    const pastDueRisk = riskReviewDate(dataset[i].ReviewDate, dataset[i].Status, true)
    if (pastDueRisk === "PAST DUE" || pastDueRisk === "NOT REVIEWED") {
      count = count + 1;
    }
    if(dataset[i].Status != "Closed" ){
      openRisks.push(dataset[i]);
    }
    else if(admin == "True" )
      closedRisks.push(dataset[i]);
  }
  result.count = count;
  result.openRisks = openRisks;
  result.closedRisks = closedRisks;
  return result;
}

export const APIheaders = (param: any) => {
  return {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    authorization: `${param}`,
  }
};
export const leadGroups = (usergrouplist: any) => {
  let leaderShipGroups = usergrouplist.filter((item: any) => {
    return item === AdminGroup || item.endsWith(".LEADS") || item.endsWith(".DH") || item.endsWith(".DL") || item.endsWith(".CRM");
  });
  return leaderShipGroups;
}
export const checkIsLeaderGroup = (item: any) => {
  return item === AdminGroup || item.endsWith(".LEADS") || item.endsWith(".DH") || item.endsWith(".DL") || item.endsWith(".CRM");
}
export const validateRKVNMenu = (usergrouplist: any) => {
  let access: any = {
    valid: false,
    admin: "False"
  }
  // Extract suffixes from list1
  const suffixesFromList1 = usergrouplist.map((usergroup: any) => usergroup.split('.').pop());

  // Check if any value from suffixesFromList1 exists in rkvnMenuAllowedList
  let isValid = suffixesFromList1.some((isLeaders: any) => rkvnMenuAllowedList.includes(isLeaders));

  // Check if AdminGroup value exists in usergrouplist
  let isAdmin = usergrouplist.some((isLeaders: any) => isLeaders === AdminGroup);

  access.valid = isValid;
  access.admin = isAdmin ? "True" : "False";

  return access;
}
export const DateDiffValue = (dateValue: any) => {
  // Get the current date
  const currentDate: any = new Date();

  // Calculate the difference in milliseconds
  const timeDiffMilliseconds = currentDate - dateValue;

  // Convert milliseconds to days
  const daysDiff = Math.floor(timeDiffMilliseconds / (1000 * 60 * 60 * 24));
  return daysDiff;

};
export const validateRiskAccess = (usergrouplist: any, accessGroups: any) => {
  // Check if any value from suffixesFromList1 exists in list2
  let isValid = usergrouplist.some((isLeaders: any) => accessGroups.some((accessItem: any) => isLeaders === accessItem.Name));
  return isValid
}
export const badge = (value: any) => {
  let colorhighlight = 'bgr-red';
  if (value) {
    if (value <= 4) {
      colorhighlight = 'bgr-blue';
    }
    else if (value === 5 || value === 6) {
      colorhighlight = 'bgr-dyellow';
    }
    else if (value === 7 || value === 8) {
      colorhighlight = 'bgr-orange';
    }
  }
  return "rk-container-" + colorhighlight;
};
export const badgeStatus = (value: any) => {
  let colorhighlight = 'bgr-blue';
  if (value === "REVIEWED")
    colorhighlight = 'bgr-blue';
  if (value === "PAST DUE")
    colorhighlight = "bgr-lightred";
  return "rk-container-" + colorhighlight;
};
export const splitMatchingValuesWithSkip = (list1: any, list2: any, skipSuffix: any) => {
  const matchingValues = [];
  const uniqueList1 = [];
  const uniqueList2 = [];

  // Iterate over list1
  for (const value of list1) {
    if (value.endsWith(skipSuffix)) {
      continue; // Skip this value
    }
    if (list2.includes(value)) {
      matchingValues.push(value);
    } else {
      uniqueList1.push(value);
    }
  }

  // Iterate over list2
  for (const value of list2) {
    if (value.endsWith(skipSuffix)) {
      continue; // Skip this value
    }
    if (!list1.includes(value)) {
      uniqueList2.push(value);
    }
  }

  return {
    matchingValues,
  }
}

export const fetchGroupMembers = async (value: any,admin: any, setIsLoading: any, validateError: any, setUserList: any) => {
  try {
    setIsLoading(true);
    await getGoogleMembersList(value, admin)
      .then(async (res) => {
        setIsLoading(false);
        const dataset = res?.data.feature;
        if (dataset != null && dataset.length > 0) {
          let set = dataset;
          setUserList(dataset);
        }
      })
      .catch((e) => {
        validateError(e);
      });
  }
  catch (error) {
    console.error('Error fetching data:', error);
  }
};