import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
 import PageWrapper from "../../layout/PageWrapper/PageWrapper";
 import Page from "../../layout/Page/Page";
 import Card, {
   CardActions,
   CardBody,
   CardHeader,
   CardLabel,
   CardTitle,
 } from "../../components/bootstrap/Card";
 import Icon from "../../components/icon/Icon";
 import Button from "../../components/bootstrap/Button";
 import ProjectMemberPopup from "./projectMemberPopup";
 import axios from "axios";
 import SubHeader, {
   SubHeaderLeft,
   SubHeaderRight,
 } from "../../layout/SubHeader/SubHeader";
 import Input from "../../components/bootstrap/forms/Input";
 import USERS, { getProjectMemberDetails, getTimeEntries, searchRoster } from '../../common/data/service';
 import Spinner from "../../components/bootstrap/Spinner";
 import classNames from "classnames";
 import useDarkMode from "../../hooks/useDarkMode";
 import { projectMemberShipTitle, validateExpiredToken } from "../../App/Constants";
 import { useNavigate } from "react-router-dom";
 import PaginationButtons, { PER_COUNT, dataPagination } from "../../components/PaginationButtons";
 import { dashboardMenu } from "../../menu";
 import { useToasts } from "react-toast-notifications";
 import Dropdown, { DropdownItem, DropdownMenu, DropdownToggle } from "../../components/bootstrap/Dropdown";
import { DateRangePicker } from "react-date-range";
import moment from "moment";
import Popovers from "../../components/bootstrap/Popovers";
 interface TotalHoursState {
   [email: string]: number; // Assuming the values are numbers, adjust the type accordingly
 }
 interface DateChangeProps {
  startDate: moment.Moment | null;
  endDate: moment.Moment | null;
}

 const TimeSheet = (props: any) => {
   // State variables
   const [projMembersData, setProjMemebersData] = useState<any[]>([]);
   const projectId = props.projectId

   const [closeBtn, setCloseBtn] = useState(false);
   const [searchTerm, setSearchTerm] = useState("");
   const [isOperational, setIsOperational] = useState('');
   const [alert, setAlert] = useState<boolean>(false);
   const [updated, setUpdated] = useState(false);
   const [created, setCreated] = useState(false);
   const { themeStatus, darkModeStatus } = useDarkMode();
   const navigate = useNavigate();
   const [currentPage, setCurrentPage] = useState<number>(1);
   const [perPage, setPerPage] = useState<number>(PER_COUNT['10']);
   const { addToast } = useToasts();
   const [searchPerPage, setSearchPerPage] = useState<number>(PER_COUNT['10']);
   const [tableLoading, setTableLoading] = useState<boolean>(false);
   const [searchDatapresent, setSearchDataPresent] = useState(false);
   const months = useMemo(() => [
     'January', 'February', 'March', 'April', 'May', 'June', 'July',
     'August', 'September', 'October', 'November', 'December'
   ], []);  const years = Array.from({ length: 10 }, (_, index) => (new Date().getFullYear() - index).toString());
   const current = new Date()
  const [isMonthOpen, setIsMonthOpen] = useState(false);
  const [isYearOpen, setIsYearOpen] = useState(false);
   current.setMonth(current.getMonth() - 1);
   const previousMonth = current.toLocaleString('default', { month: 'long' });
   const [totalHours, setTotalHours] = useState<TotalHoursState>({});
   const intialMonth = months.indexOf(previousMonth) + 1
   const initialYear = parseInt(String(current.getFullYear()))
   // State variables
   const [selectedMonth, setSelectedMonth] = useState<string>(previousMonth);
   const [selectedYear, setSelectedYear] = useState<string>(String(current.getFullYear()));
   const url = window.location.pathname;
   const segments = url.split('/');
   const lastSegment = segments[2];
   const [projectName, setProjectName] = useState(lastSegment);
   const [timeEntryLoad, setTimeEntryLoad] = useState<boolean>(false);
   const [clientName, setClientName] = useState('');
   const goToProjectsPage = useCallback((message: string, delayTime = 0) => {
     navigate(`../${dashboardMenu.processvineListpage.path}`);
     addToast(message, { id: 'ACCESS_DENIED', appearance: 'error', autoDismiss: true })
   }, [navigate, addToast]);
   const convertToIst = (dateString: string) => {
    const date = new Date(dateString);
    const istDate = new Date(date.toLocaleString('en-US', { timeZone: 'Asia/Kolkata' }));
    const year = istDate.getFullYear();
    const month = String(istDate.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const day = String(istDate.getDate()).padStart(2, '0');  
    return `${year}-${month}-${day}`;
  }
  const [startDate, setStartDate] = useState<moment.Moment | null>(
    moment().subtract(1, 'month').startOf('month').startOf('day').local()
  );
  const [endDate, setEndDate] = useState<moment.Moment | null>(
    moment().subtract(1, 'month').endOf('month').startOf('day').local()
  );
  const [date, setDate] = useState({
		selection: {
			startDate: (startDate || moment()).local().toDate(),
			endDate: (endDate || moment()).local().toDate(),
			key: 'selection',
		}
	});
   // Fetch data on component mount
   useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        setTimeEntryLoad(true);
        const projMemberRes = await getProjectMemberDetails({ projectId });
        const projMemberData = projMemberRes?.data?.data;
        setProjMemebersData(projMemberData);
        setIsOperational(projMemberRes?.data?.role);
        if (projMemberRes?.data?.data[0]?.client_name ) {
          setClientName(projMemberRes?.data?.data[0]?.client_name)
          const timeEntriesRes = await getTimeEntries({
            startDate: startDate ? convertToIst(startDate.toString()) : '',
            endDate: endDate ? convertToIst(endDate.toString()) : '',
            projectName: projectName,
            accountName: projMemberRes?.data?.data[0]?.client_name
          });
          if (timeEntriesRes?.data?.time_entries) {
            setTotalHours(timeEntriesRes.data.time_entries);
          } else if (timeEntriesRes?.error === 'Project not found') {
            goToProjectsPage("The project name does not match with the project name in AgileVine");
          } else {
            goToProjectsPage("You don't have access to Timesheets!");
          }
        }
      } catch (e) {
        if (e instanceof Error) {
        if (!validateExpiredToken(e.message)) {
          navigate('/');
          window.location.reload();
        }
      } }finally {
        setIsLoading(false);
        setTimeEntryLoad(false);
      }
    };

    fetchData();
  }, [projectId, projectName, navigate, goToProjectsPage, startDate, endDate]); 
   // Function to handle search and update the member list
   const handleSearchClick = async (searchValue: any, operation?: any) => {
     setTableLoading(true)
     try {
       // Use axios to fetch data from the API
       const response = await searchRoster({ projectID: projectId, searchValue: searchValue });
       if (response.data.length === 0) {
         setSearchDataPresent(true)
       }
       setSearchPerPage(PER_COUNT['5']);
       setCurrentPage(1);
       setProjMemebersData(response.data);
       setAlert(true);
       setTableLoading(false)
     } catch (error) {
       navigate('/');
       window.location.reload();
       console.error("Error fetching data:", error);
     }
   };
 
 
   // Function to format a date string
   const formatDate = (dateString: string) => {
     const timestamp = new Date(dateString);
     const day = String(timestamp.getDate()).padStart(2, '0'); // Ensure leading zero
     const month = timestamp.toLocaleString('default', { month: 'short' }).slice(0, 3);
     const year = timestamp.getFullYear();
     const formattedDate = `${day}-${month}-${year}`;
     return formattedDate;
   };
 
   // Function to handle search input change
   const handleSearchChange = (e: any) => {
     e.target.value != "" ? setCloseBtn(true) : setCloseBtn(false);
     setSearchTerm(e.target.value);
     handleSearchClick(e.target.value);
   };
 
   // Function to clear the search input and reset the member list
   const handleSearchClose = () => {
     setSearchTerm("");
     handleSearchClick("");
     setCloseBtn(false);
   };
   const handlePageChange = (page: number) => {
     setCurrentPage(page);
   };
   const itemsOnPage = dataPagination(projMembersData, currentPage, searchTerm ? searchPerPage : perPage);
 
   // State for loading indicator
   const [isLoading, setIsLoading] = useState<boolean>(false);
 
   const handleApplyButtonClick = () => {
     setIsLoading(true);
     getTimeEntries({
      startDate: convertToIst(date.selection.startDate.toString()),
      endDate: convertToIst(date.selection.endDate.toString()),
      accountName: clientName,
      projectName: projectName
     })
       .then((res) => {
         if (res && res.data && res?.data?.time_entries) {
           setTotalHours(res?.data?.time_entries);
         } else if (res && res?.error && res?.error === 'Project not found') {
           goToProjectsPage("The project name does not match with the project name in AgileVine", 5000);
         } else {
           goToProjectsPage("You don't have access to Timesheets!", 5000);
         }
       })
       .catch((e) => {
         if (!validateExpiredToken(e.message)) {
           navigate('/');
           window.location.reload();
         }
         console.error(e.message);
       })
       .finally(() => {
        setIsLoading(false);
       });
   };
   const handleMonthSelection = (month: React.SetStateAction<string>) => {
    setSelectedMonth(month);
    setIsMonthOpen(false); // Close the dropdown after selecting a month
  };
  const handleYearSelection = (year: React.SetStateAction<string>) => {
    setSelectedYear(year)
    setIsYearOpen(false); // Close the dropdown after selecting a year
  };



	const datePicker = (
		<DateRangePicker
			onChange={(item) => setDate({ ...date, ...item })}
			moveRangeOnFirstSelection={false}
			retainEndDateOnFirstSelection={false}
			months={2}
			ranges={[date.selection]}
			direction='horizontal'
			rangeColors={[
				String(process.env.REACT_APP_PRIMARY_COLOR)
			]}
		/>
	);
   return (
     <PageWrapper className='prms-page-wrapper' title={projectMemberShipTitle}>
       <Page>
         <Card stretch data-tour="list">
           <CardHeader>
 
             <CardLabel>
               <CardBody> </CardBody>
               <CardTitle>Timesheets
 
               </CardTitle>
             </CardLabel>
             <CardActions>
             <div className='dropdown-class' style={{ zIndex: 2000 }}>
               <div className="shadow-none w-100 btn-color ">
               <Popovers
						placement='bottom-end'
						className='mw-100 overflow-hidden'
						data-tour='date-range-menu'
						bodyClassName='p-0'
						trigger='click'
						desc={datePicker}
            safeDescRender={true}>
						<Button className="btn  shadow-none w-100 btn-color " size={"lg"}>
							{`${moment(date.selection.startDate).format('DD MMM YYYY')} - ${moment(
								date.selection.endDate,
							).format('DD MMM YYYY')}`}
						</Button>
					</Popovers>
               </div>
               <div>
                 <Button className="btn  shadow-none w-100 btn-color " size={"lg"} onClick={handleApplyButtonClick} >Apply</Button>
               </div>


             </div>
             </CardActions>
           </CardHeader>
           <SubHeader className='div-text-box'>
             <SubHeaderLeft>
               <label
                 className="border-0 bg-transparent cursor-pointer me-0"
                 htmlFor="searchInput"
               >
                 <Icon icon="Search" size="2x" color="primary" />
               </label>
               <Input
                 id="searchInput"
                 className="border-0 shadow-none bg-transparent"
                 placeholder="Search by Name and Status"
                 value={searchTerm || ""}
                 onChange={(e: any) => {
                   handleSearchChange(e);
                 }}
               />
               <Button
                 className={classNames(
                   { 'processvine-btn-close': !closeBtn },
                   'btn-close'
                 )}
                 type='button'
                 aria-label='Close'
                 onClick={() => handleSearchClose()}
               />
             </SubHeaderLeft>
           </SubHeader>
           <CardBody
             className="table-responsive project-membership-table-header"
             isScrollable
           >
             <table className={`table table-modern table-hover processvine-table prms-table ${tableLoading ? 'height-100' : ''}`}>
               <thead>
                 <tr>
                   <th className="prms-table-header" scope="col">
                     Name
                   </th>
                   <th
 
                     scope="col"
                     className="prms-table-header"
                   >
                     Hours
                     <Icon size="lg" />
                   </th>
                  
                   <th scope="col" className="prms-table-header">
                     Client Role
                     <Icon size="lg" />
                   </th>

                   <th scope="col" className="prms-table-header">
                     Role
                     <Icon size="lg" />
                   </th>
 
                   <th scope="col" className="prms-table-header">
                     Status
                     <Icon size="lg" />
                   </th>
                   <th scope="col" className="prms-table-header">
                     Start Date
                   </th>
                   <th scope="col" className="prms-table-header">
                     End Date
                   </th>
 
 
 
 
                   <th
 
                     scope="col"
                     className="prms-table-header"
                   >
                     Cost Type
                     <Icon size="lg" />
                   </th>
                   <th
 
                     scope="col"
                     className="prms-table-header"
                   >
                     Comments
                     <Icon size="lg" />
                   </th>
                 </tr>
               </thead>
               <tbody >
                 {tableLoading ? (
                   <tr>
                     <td className="text-center processvine-spinner">
                       <Spinner inButton />
                     </td>
                   </tr>
                 ) : (
                   projMembersData.length > 0 ?
                     <>
                       {itemsOnPage.map((row) => (
                         <tr key={row.id}>
                           <td>{row.user ? row.user.userfullname : ""}</td>
                           <td className="center-align-table-data">
                             {totalHours[row?.email as string] ? totalHours[row?.email as string] : "-"}
                           </td>
                           <td className={row.client_role ? '' : 'center-align-table-data'} >{row.client_role ? row.client_role.name : "-"}</td>
                              <td className={row.sysvine_role ? '' : 'center-align-table-data'}>{row.sysvine_role ? row.sysvine_role.name : "-"}</td>
                           <td className='center-align-table-data'>{row.status ? row.status.name : "-"}</td>
                           <td className='center-align-table-data'>
                             {row.start_date ? formatDate(row.start_date) : "-"}
                           </td>
                           <td className='center-align-table-data'>
                             {row.end_date ? formatDate(row.end_date) : "-"}
                           </td>
 
 
                           <td className='center-align-table-data'>{row.cost_type ? row.cost_type.name : "-"}</td>
                           <td className='center-align-table-data'>-</td>
                         </tr>
                       ))}
                     </>
                     : (
                       isLoading ? null : (searchDatapresent ? (<div className='text-center processvine-no-projects'>
                         No matches found for the searched criteria
 
                       </div>) : (
                         <div className='text-center processvine-no-projects'>
                           No Members are Mapped to this Project
                         </div>)
                       )
                     )
 
                 )}
               </tbody>
             </table>
             {(isLoading || props.load || props.isLoading || timeEntryLoad) && (
               <div className="processvine-overlay-box">
                 <Spinner
                   className="text-center processvine-no-projects processvine-spinner"
                   inButton
                 />
               </div>
             )}
 
 
           </CardBody>
           <PaginationButtons
             setCurrentPage={handlePageChange}
             currentPage={currentPage}
             perPage={perPage}
             setPerPage={setPerPage}
             data={projMembersData}
             label="Members"
           />
         </Card>
 
       </Page>
     </PageWrapper>
   );
 };
 
 export default TimeSheet;