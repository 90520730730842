import { createContext } from 'react';

interface AuthContext {
    idToken: string;
    setIdToken: (idToken: string) => void;
}

export const AuthContext = createContext<AuthContext>({
    idToken: '',
    setIdToken: (idToken: string) => {},
});