import * as React from 'react';

function EmployeesIcons(props) {
	return (
        <svg width="31" height="20" viewBox="0 0 31 35" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12.9004 12.92C16.4682 12.92 19.3604 10.0278 19.3604 6.46C19.3604 2.89224 16.4682 0 12.9004 0C9.33267 0 6.44043 2.89224 6.44043 6.46C6.44043 10.0278 9.33267 12.92 12.9004 12.92Z" fill="white"/>
        <path d="M11.0404 28.7307V33.7307C11.0404 33.9959 11.1458 34.2503 11.3333 34.4378C11.5209 34.6253 11.7752 34.7307 12.0404 34.7307H29.0404C29.3057 34.7307 29.56 34.6253 29.7475 34.4378C29.9351 34.2503 30.0404 33.9959 30.0404 33.7307V19.7307C30.0404 19.4655 29.9351 19.2111 29.7475 19.0236C29.56 18.8361 29.3057 18.7307 29.0404 18.7307H22.0404C22.0203 18.7307 21.9351 18.7411 21.7475 18.5536C21.56 18.3661 21.3057 18.2607 21.0404 18.2607C20.7752 18.2607 20.5209 18.3661 20.3333 18.5536C20.1458 18.7411 20.1492 18.7307 20.0404 18.7307H18.0404V15.1507C16.3411 14.8732 14.6223 14.7327 12.9004 14.7307C9.10324 14.7146 5.34858 15.5303 1.90044 17.1207C1.33292 17.3885 0.854144 17.8135 0.520913 18.3453C0.187683 18.877 0.0139878 19.4932 0.020437 20.1207V28.7307H11.0404ZM28.0404 32.7307H13.0404V20.7307H20.0404C20.0404 20.7307 20.2429 20.7307 20.4421 20.7307C20.5203 20.7307 20.755 20.7307 21.0203 20.7307C21.2855 20.7307 21.7 20.7307 21.7859 20.7307H22.0404H28.0404V32.7307Z" fill="white"/>
        <path d="M18 29.0138V30.3846C18 30.5478 18.0585 30.7044 18.1627 30.8198C18.2669 30.9352 18.4082 31 18.5556 31H22.4444C22.5918 31 22.7331 30.9352 22.8373 30.8198C22.9415 30.7044 23 30.5478 23 30.3846V29C22.9997 28.9045 22.9795 28.8103 22.9409 28.7249C22.9023 28.6395 22.8463 28.5651 22.7774 28.5077L20.9628 27L22.7778 25.4923C22.8466 25.4348 22.9025 25.3605 22.9411 25.2751C22.9796 25.1897 22.9998 25.0955 23 25V23.6154C23 23.4522 22.9415 23.2956 22.8373 23.1802C22.7331 23.0648 22.5918 23 22.4444 23H18.5556C18.4082 23 18.2669 23.0648 18.1627 23.1802C18.0585 23.2956 18 23.4522 18 23.6154V24.9862C18 25.0814 18.0199 25.1753 18.0583 25.2605C18.0966 25.3457 18.1522 25.4199 18.2208 25.4773L20.0396 27L18.2208 28.5227C18.1523 28.5802 18.0968 28.6544 18.0584 28.7396C18.0201 28.8248 18.0001 28.9187 18 29.0138Z" fill="white"/>
        </svg>
        
	);
}

export default EmployeesIcons;
