import React, { useCallback, useEffect, useState } from 'react';
import ReactCreditCards, { Focused } from 'react-credit-cards';
import Payment from 'payment';
import { FormikHelpers, FormikValues, useFormik } from 'formik';
import classNames from 'classnames';
import Card, {
    CardActions,
    CardBody,
    CardHeader,
    CardLabel,
    CardTitle,
} from '../../components/bootstrap/Card';
import Button from '../../components/bootstrap/Button';
import Modal, { ModalBody, ModalHeader, ModalTitle } from '../../components/bootstrap/Modal';
import FormGroup from '../../components/bootstrap/forms/FormGroup';
import Input from '../../components/bootstrap/forms/Input';
import ReactCreditCardsContainer from '../../components/extras/ReactCreditCardsContainer';
import useDarkMode from '../../hooks/useDarkMode';
import MySelect from '../../components/bootstrap/forms/Select';
import Textarea from '../../components/bootstrap/forms/Textarea';
import { createNewProject, getBusinessUnits, getClients, getCurrencies, getDepartmentList, getProjectNames, getProjects } from '../../common/data/service';
import { ProjectStatusOptions, ProjectType, validateExpiredToken } from '../../App/Constants';
// @ts-ignore
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
// @ts-ignore
import Select from "react-select";
import DatePickerIcon from '../../components/bootstrap/forms/DatePickerIcon';
import Spinner from '../../components/bootstrap/Spinner';
import { getLoggedInUser } from '../../common/data/commonService';
import { useNavigate } from 'react-router-dom';
import { IMainUsers } from '../../model/MainUsers';
import UseColourStyles from "./SelectStyles"
import { useToasts } from 'react-toast-notifications';
const AddProject = (props: any) => {
    const { darkModeStatus } = useDarkMode();
    const [businessUnits, setBusinessUnits] = useState<any[]>([]);
    const [currencies, setcurrencies] = useState<any[]>([]);
    const [clients, setclients] = useState<any[]>([]);
    const [departments, setdepartments] = useState<any[]>([]);
    const [baseProjects, setbaseProjects] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [modalStatus, setModalStatus] = useState<boolean>(false);
    const [isBusinessUnitPresent, setBusinessUnitPresent] = useState(false);
    const [takenProjectNames, setTakenProjectNames] = useState<any[]>([])
    const [isLoadingDepartment, setIsLoadingDepartment] = useState(false);
    const [isLoadingAccounts, setIsLoadingAccounts] = useState(false);
    const [isLoadingBusinessUnits, setIsLoadingBusinessUnits] = useState(false);
    const [isLoadingBaseProjects, setIsLoadingBaseProjects] = useState(false);
    const [isLoadingCurrencies, setIsLoadingCurrencies] = useState(false);
    const [isLoadingLoggedInUser, setIsLoadingLoggedInUser] = useState(false);
    const [userDetails, setUserDetails]: any = useState<IMainUsers[]>([]);
    const [startDateMandatory, setStartDateMandatory] = useState<boolean>(false);
    const [endDateMandatory, setEndDateMandatory] = useState<boolean>(false);
    const { addToast,removeAllToasts } = useToasts();
    const navigate = useNavigate();
    const handlemodalClose = () => {
        props.addNewProject();
    };
    useEffect(() => {
        setModalStatus(true);
    }, [])
    useEffect(() => {
        setIsLoadingBusinessUnits(true);
        setIsLoadingCurrencies(true);
        setIsLoadingAccounts(true);
        setIsLoadingBaseProjects(true);
        setIsLoadingLoggedInUser(true)
        getBusinessUnits()
            .then((res) => {
                setBusinessUnits(res?.data)
            })
            .catch((e) => {
                console.log(e.message);
            })
            .finally(() => {
                setIsLoadingBusinessUnits(false);
            });
        getCurrencies()
            .then((res) => {
                setcurrencies(res?.data)
            })
            .catch((e) => {
                console.log(e.message);
            })
            .finally(() => {
                setIsLoadingCurrencies(false);
            });
        getClients()
            .then((res) => {
                setclients(res?.data)
            })
            .catch((e) => {
                console.log(e.message);
            })
            .finally(() => {
                setIsLoadingAccounts(false);
            });
        getDepartmentList()
            .then((res) => {
                setdepartments(res?.data)
            })
            .catch((e) => {
                console.log(e.message);
            })
            .finally(() => {
                setIsLoadingDepartment(false);
            });
        getProjects()
            .then((res) => {
                setIsLoading(false);
                setbaseProjects(res?.data)
            })
            .catch((e) => {
                console.log(e.message);
            })
            .finally(() => {
                setIsLoadingBaseProjects(false);
            });
        getLoggedInUser()
            .then((res) => {
                setUserDetails(res?.data);
            })
            .catch((e) => {
                if (!validateExpiredToken(e.message)) {
                    navigate('/');
                    window.location.reload();
                }
            })
            .finally(() => {
                setIsLoadingLoggedInUser(false);
            });

    }, [navigate]);


    const formik = useFormik({
        initialValues: {
            businessUnit: '',
            department: '',
            account: '',
            project: '',
            startDate: '',
            endDate: '',
            status: '',
            currency: '',
            projectType: '',
            description: '',
            baseProject: '',
            estimationHours: '',
        },
        validate: (values) => {
            const errors: Partial<FormikValues> = {};
            if (values.project) {
                if (values.project.length !== 4) {
                    errors.project = "Must be 4 characters";
                } else {
                    // Check if all characters are alphabets in uppercase
                    if (!/^[A-Z]+$/.test(values.project)) {
                        errors.project = "Must contain only uppercase alphabets";
                    }
                    else {
                        if (takenProjectNames.includes(values.project)) {

                            errors.project = "This project has already been created. ";
                        }

                    }

                }
            }
            else {
                errors.project = "Project is required"
            }

            if (!values.department) {
                errors.department = "Department is required";
            }
            // Validation for the account field


            // Validation for the status field
            if (!values.status) {
                errors.status = "Status is required";
            }
            if(values.status ==='Active' || values.status === 'In-progress'){
                setStartDateMandatory(true)
                if(!values.startDate){
                errors.startDate = "Start Date is mandatory"}

            }
            else{
                setStartDateMandatory(false)
            }
            if(values.status ==='Deferred' || values.status === 'Closed' || values.status === 'Completed'){
                setEndDateMandatory(true)
                if(!values.endDate){
                errors.endDate = "End Date is mandatory"}

            }
            else{
                setEndDateMandatory(false)
            }

            // Validation for the currency field
            if (!values.currency) {
                errors.currency = "Currency is required";
            }

            // Validation for the projectType field
            if (!values.projectType) {
                errors.projectType = "Type is required";
            }

            // Validation for the businessUnit field
            if (!values.businessUnit) {
                errors.businessUnit = "Business Unit is required";
            }
            if (!values.account) {
                errors.account = "Account is required";
            }

            return errors;
        },

        async onSubmit<Values>(
            values: FormikValues,
            formikHelpers: FormikHelpers<Values>
        ): Promise<void | Promise<any>> {
            let isValid = false;
            console.log("business", values.businessUnit)

            if (values.department === "" || values.account === '' || values.status === "" || values.project === '' || values.currency === '' || values.projectType === '' || values.businessUnit === '' || takenProjectNames.includes(values.project)) {
            }

            else {
                isValid = true;

            }

            try {
                if (isValid) {
                    await createNewProject({
                        businessUnit: values.businessUnit,
                        department: values.department,
                        account: values.account,
                        project: values.project,
                        startDate: values.startDate,
                        endDate: values.endDate,
                        status: values.status,
                        currency: values.currency,
                        projectType: values.projectType,
                        description: values.description,
                        baseProject: values.baseProject,
                        estimationHours: values.estimationHours,
                        createdBy: userDetails.id
                    })
                    .then((res) => {
                        const jobStatus = {
                            projectId: res.data.data.id,
                            jobId: res?.data?.job_id
                        };
                        
                        localStorage.setItem('jobStatus', JSON.stringify(jobStatus));
                        props.fetchJobStatus()
                    })
                    .catch((e) => {
                        console.log(e.message);
                    })
                    setModalStatus(false)
					addToast("Project created successfully Google Groups are being created.", {id: 'ACCESS_DENIED', appearance: "warning", autoDismiss: true});
                }


            } catch (error) {
                console.error("API error:", error);
            }
        },
    });
    const [showMessage, setShowMessage] = useState(false);
    const [focused, setFocused] = useState<Focused>('number');
    const handleInputFocus = ({ target }: { target: { name: Focused } }) => setFocused(target.name);
    console.log("clicked", formik.values)
    const BaseProjectOptions = baseProjects.map((role) => {
        // Check if project_name is a valid project name
        const isValidProjectName =
            role.project_name.length === 4 &&
            /^[A-Z]+$/.test(role.project_name);

        if (isValidProjectName) {
            return {
                label: role.project_name,
                value: role.id.toString(),
            };
        } else {
            return null;
        }
    }).filter((option) => option !== null);

    const AccountOptions = clients.map((client) => {
        // Check if project_name is a valid project name
        const isValidProjectName =
            client.client_name.length === 4 &&
            /^[A-Z]+$/.test(client.client_name);

        if (isValidProjectName) {
            return {
                label: client.client_name,
                value: client.id.toString(),
            };
        } else {
            return null;
        }
    }).filter((option) => option !== null);

    const handleDescriptionChange = (event: { target: { value: any } }) => {
        const inputValue = event.target.value;
        const truncatedValue = inputValue.slice(0, 500);
        formik.setFieldValue('description', truncatedValue);

        if (inputValue.length < 500) {
            formik.handleChange(event);
            setShowMessage(false);

        } else {


            setShowMessage(true);
        }
    };
    const getAlreadyTakenProjects = useCallback(async (account: string) => {
        setIsLoading(true);
        try {
            const projectsData = await getProjectNames({
                account: account
            });
            setTakenProjectNames(projectsData?.data?.project_names);
            console.log("lol", projectsData?.data?.project_names)

            //   setUsers(usersData);
            setIsLoading(false);
        } catch (error) {
            console.error("Error fetching users:", error);
        }
    }, []);
    useEffect(() => {
        if (formik.values.account !== "") {
            //   setIsLoading(true);
            console.log(formik.values.account);
            getAlreadyTakenProjects(formik.values.account)
        }
    }, [formik.values.account, getAlreadyTakenProjects]);

    const calculateMaxDateForStartDate = (startDate: string | number | Date): Date | null => {
        if (startDate) {
            const maxDate = new Date(startDate);
            maxDate.setDate(maxDate.getDate());
            return maxDate;
        }
        return null;
    };

    const calculateMinDateForEndDate = (startDate: string | number | Date): Date | null => {
        if (startDate) {
            const minDate = new Date(startDate);
            minDate.setDate(minDate.getDate() + 1);
            return minDate;
        }
        return null;
    };
    const handleUnitChange = (event: { target: { value: any } }) => {
        setIsLoadingDepartment(true)
        const inputValue = event.target.value;
        formik.setFieldValue('businessUnit', inputValue);
        formik.handleChange(event);
        formik.setFieldValue('department', '');
        getDepartmentList({unitId: Number(inputValue)})
            .then((res) => {
                setdepartments(res?.data)
            })
            .catch((e) => {
                console.log(e.message);
            })
            .finally(() => {
                setIsLoadingDepartment(false);
            });
    };
    return (
        <>
            <Modal
                onClose={handlemodalClose}
                setIsOpen={setModalStatus}
                isOpen={modalStatus}
                size='xl'
                titleId='add-new-card'
                isCentered>
                <ModalHeader setIsOpen={setModalStatus}>
                    <ModalTitle id='add-new-card'>Add New Project</ModalTitle>
                </ModalHeader>
                <ModalBody>

                    <div className='row'>
                        <div className='col-md-16'>

                            <form className='row g-4'>
                                <FormGroup  className='col-4' id="businessUnit" labelClassName="label-font-color" label='Business Unit' isRequired={true}>
                                    {/* <FormGroup id="businessUnit" labelClassName="label-font-color" label="Business Unit" isRequired={true}> */}
                                    <MySelect
                                        id="businessUnit"
                                        className="processvine-form-font-weight remove-icon"
                                        ariaLabel="businessUnit"
                                        placeholder="Select Business Unit"
                                        onChange={handleUnitChange}
                                        list={[
                                            { value: '', text: 'Please Select' },  // Add the "None" option
                                            ...businessUnits.map((unit) => ({
                                                value: unit.id,
                                                text: unit.unitname,
                                            })),
                                        ]}

                                        onBlur={formik.handleBlur}
                                        value={formik.values.businessUnit}
                                        isValid={formik.isValid}
                                        isTouched={formik.touched.businessUnit}
                                        invalidFeedback={formik.errors.businessUnit}
                                        validFeedbackNeeded={true}

                                    />


                                </FormGroup>
                                <FormGroup className='col-4' id="department" labelClassName="label-font-color"  label='Department' isRequired={true}>
                                    <MySelect
                                        className="processvine-form-font-weight remove-icon"
                                        ariaLabel="businessUnit"
                                        placeholder={formik.values.businessUnit ? 'Enter Department' : 'Please Select a Business Unit'}
                                        id="department"
                                        onChange={formik.handleChange}
                                        list={[
                                            { value: '', text: 'Please Select' }, // Add the "None" option
                                            ...departments.map((department) => ({
                                                value: department.id,
                                                text: department.deptname,
                                            })),
                                        ]}
                                        onBlur={formik.handleBlur}
                                        disabled={!formik.values.businessUnit}
                                        value={formik.values.department}
                                        isValid={formik.isValid}
                                        isTouched={formik.touched.department}
                                        invalidFeedback={formik.errors.department}
                                        validFeedbackNeeded={true}

                                    />
                                </FormGroup>
                                <FormGroup className='col-4' id='account' labelClassName="label-font-color"  label='Account' isRequired={true}>
                                    <Select
                                        options={[{ value: "", label: 'Please Select' }, ...AccountOptions]}
                                        isSearchable={true}
                                        placeholder="Search and choose..."
                                        value={formik.values.account === "" ? null : undefined}
                                        onChange={(selectedOption: { value: any }) => {
                                            if (selectedOption) {
                                                formik.setFieldValue("account", selectedOption.value);
                                            } else {
                                                console.log("No option selected.");
                                            }
                                        }}


                                        styles={UseColourStyles((formik.errors.account && formik.touched.account))}

                                    /><>
                                        {formik.errors.account && formik.touched.account && (
                                            <span className="validation-message"> {formik.errors.account} </span>
                                        )}</>
                                </FormGroup>
                                <FormGroup className='col-4' id='project'
                                    label={`Project`}
                                    labelClassName="label-font-color" isRequired={true}>
                                    <Input
                                        // id='project'
                                        placeholder={formik.values.account ? 'Enter Project Code' : 'Please Select an Account'}
                                        className="processvine-form-font-weight remove-icon"
                                        autoComplete='additional-name'
                                        onChange={formik.handleChange}
                                        value={formik.values.project}
                                        onBlur={formik.handleBlur}
                                        isValid={formik.isValid && formik.touched.project}
                                        isTouched={formik.touched.project}
                                        invalidFeedback={formik.errors.project}
                                        validFeedbackNeeded={true}
                                        disabled={!formik.values.account}
                                        validFeedback='Looks good!'

                                    />
                                </FormGroup>
                                <FormGroup className='col-4' id='status' labelClassName="label-font-color" label='Status' isRequired={true}>
                                    <MySelect
                                        id='status'
                                        className="processvine-form-font-weight remove-icon"
                                        ariaLabel="businessUnit"
                                        placeholder="Select Status"
                                        list={[
                                            { value: '', text: 'Please Select' }, // Added None option
                                            ...ProjectStatusOptions.map((project: { key: any; value: any; }) => ({
                                                value: project.value,
                                                text: project.key,
                                            }))
                                        ]}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.status}
                                        isValid={formik.isValid}
                                        isTouched={formik.touched.status}
                                        invalidFeedback={formik.errors.status}
                                        validFeedbackNeeded={true}
                                    />

                                </FormGroup>
                                <FormGroup className='col-4' id='baseProject' label='Base Project' labelClassName="label-font-color" >
                                    <Select
                                        options={[{ value: "", label: 'Please Select' }, ...BaseProjectOptions]}
                                        isSearchable={true}
                                        placeholder="Search and choose..."
                                        value={formik.values.baseProject === "" ? null : undefined}
                                        onChange={(selectedOption: { value: any; }) => {
                                            if (selectedOption) {
                                                formik.setFieldValue("baseProject", selectedOption.value);
                                            } else {
                                                console.log("No option selected.");
                                            }
                                        }}
                                        styles={UseColourStyles()}
                                    />
                                </FormGroup>

                                <FormGroup className='col-4' id='currency' label='Currency' labelClassName="label-font-color"  isRequired={true}>
                                    <MySelect
                                        id='currency'
                                        className="processvine-form-font-weight remove-icon"
                                        ariaLabel="businessUnit"
                                        placeholder="Select Currency"
                                        list={[
                                            { value: '', text: 'Please Select' },
                                            ...currencies.map((currency) => ({
                                                value: currency.id,
                                                text: currency.currencyname,
                                            }))
                                        ]}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.currency}
                                        isValid={formik.isValid}
                                        isTouched={formik.touched.currency}
                                        invalidFeedback={formik.errors.currency}
                                        validFeedbackNeeded={true}
                                        onChange={formik.handleChange}
                                    />
                                </FormGroup>

                                <FormGroup className='col-4' id='projectType' label='Type' labelClassName="label-font-color"  isRequired={true}>
                                    <MySelect
                                        id='projectType'
                                        className="processvine-form-font-weight remove-icon"
                                        ariaLabel="businessUnit"
                                        placeholder="Select Type"
                                        onChange={formik.handleChange}
                                        list={[
                                            { value: '', text: 'Please Select' }, // Add the "None" option
                                            ...ProjectType.map((project: { key: any; value: any; }) => ({
                                                value: project.key,
                                                text: project.value,
                                            }))
                                        ]}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.projectType}
                                        isValid={formik.isValid}
                                        isTouched={formik.touched.projectType}
                                        invalidFeedback={formik.errors.projectType}
                                        validFeedbackNeeded={true}
                                    />
                                </FormGroup>

                                <FormGroup id='startDate' label='Start Date' labelClassName="label-font-color" className="datepicker-container col-4" isRequired={startDateMandatory}>
                                    <DatePicker
                                        showIcon
                                        className={`red-border processvine-form-font-weight form-control processvine-project-date-format ${
                                            (!formik.values.startDate && startDateMandatory) ? 'mandatory-field' : ''
                                            }`}
                                        selected={formik.values.startDate ? new Date(formik.values.startDate) : null}
                                        onChange={(date: any) => {
                                            console.log("Received date:", date);
                                            formik.setFieldValue("startDate", date);
                                        }}
                                        maxDate={calculateMaxDateForStartDate(formik.values.endDate)}
                                        dateFormat="dd-MMM-yyyy"
                                        placeholderText="Click to select a date"
                                        icon={<DatePickerIcon />}
                                    />
                                    <>
                                        {true && (
                                            <span className="validation-message"> {formik.errors.startDate} </span>
                                        )}</>
                                </FormGroup>
                                <FormGroup className='datepicker-container col-4' id='expiry' labelClassName="label-font-color" label='End Date' isRequired = {endDateMandatory}>
                                    <DatePicker
                                        showIcon

                                        className={`red-border processvine-form-font-weight form-control processvine-project-date-format ${
                                            (!formik.values.endDate && endDateMandatory) ? 'mandatory-field' : ''
                                            }`}
                                        selected={formik.values.endDate ? new Date(formik.values.endDate) : null}
                                        minDate={calculateMinDateForEndDate(formik.values.startDate)}
                                        onChange={(date: any) => {
                                            console.log("Received date:", date);
                                            formik.setFieldValue("endDate", date);
                                        }}

                                        icon={<DatePickerIcon />}
                                        dateFormat="dd-MMM-yyyy"
                                        placeholderText="Click to select a date"
                                    />
                                    <>
                                        {true && (
                                            <span className="validation-message"> {formik.errors.endDate} </span>
                                        )}</>
                                </FormGroup>
                                <FormGroup
                                    id='estimationHours'
                                    label='Estimation Hours'
                                    className='col-4'
                                    labelClassName="label-font-color"
                                >
                                    <Input
                                        className="processvine-form-font-weight"
                                        type='text'
                                        placeholder='Enter Estimation Hours'
                                        onChange={(e: any) => {
        // Ensure the entered value is not negative
                                            const inputValue = e.target.value;
                                            if (inputValue >= 0) {
                                                formik.handleChange(e);
                                            }
                                        }}
                                        value={formik.values.estimationHours}
                                    />

                                   
                                </FormGroup>
                                {/* <FormGroup className='col-4' id='expiry' label='Description'> */}
                                <FormGroup label={`Description (Note: Max 500 Characters allowed)`} labelClassName="label-font-color" id='description'>

                                    <Textarea
                                        rows={5}
                                        // onInput={handleInput}
                                        id="Description"
                                        maxLength={500}
                                        onChange={handleDescriptionChange}
                                        // value={formik.values.description}
                                        className="processvine-form-font-weight"
                                        // onBlur={formik.handleBlur}
                                        // value={formik.values.description}
                                        // isValid={formik.isValid}
                                        // isTouched={formik.touched.description}
                                        invalidFeedback={formik.errors.description}

                                    />
                                    <>
                                        {showMessage && (
                                            <p className="description-validation">
                                                Exceeded the 500 Characters limit.
                                            </p>
                                        )}
                                    </>


                                </FormGroup>

                                <div className='col-4 offset-md-8'>
                                    <Button
                                        type='button'
                                        color='info'
                                        icon='Save'
                                        isDisable={!formik.isValid && !!formik.submitCount}
                                        className='float-end' // Align the button to the right
                                        onClick={() => {
                                            formik.handleSubmit();
                                        }}
                                    >
                                        Save
                                    </Button>
                                </div>
                            </form>
                        </div>

                    </div>
                </ModalBody>
                <>
                    {(isLoading || isLoadingAccounts || isLoadingBaseProjects || isLoadingBusinessUnits|| isLoadingCurrencies || isLoadingDepartment || isLoadingLoggedInUser) && (
                        <div className="processvine-overlay-box">
                            <Spinner
                                className="text-center processvine-no-projects processvine-spinner"
                                inButton
                            />
                        </div>
                    )}
                </>
            </Modal>
        </>
    );
};

export default AddProject;