// src/components/RichTextEditor.js

import React, { useState, useEffect, useRef } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from "draftjs-to-html";
import htmlToDraft from 'html-to-draftjs';
import Button from '../../components/bootstrap/Button';
import useDarkMode from "../../hooks/useDarkMode";
import classNames from 'classnames';
import DOMPurify from 'dompurify';
import { useFormik } from 'formik';
import { updateProjectDetail } from '../../common/data/service';
import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
} from '../../components/bootstrap/Modal';
import FormGroup from "../../components/bootstrap/forms/FormGroup";
import Icon from "../../components/icon/Icon";
import Alert, { AlertHeading } from '../../components/bootstrap/Alert';
import { validateExpiredToken } from '../../App/Constants';
import { useNavigate } from 'react-router-dom';

const Description = (props: any) => {
  const { projectId, projectDetailsValues, setIsLoading, aedAccess, handleEditButtonClick } = props;
  const { darkModeStatus } = useDarkMode();
  const flag = useRef(true);
  const [isEditOpen, setIsEditOpen] = useState<boolean>(false);
  const [editContent, setEditContent] = useState("")
  const [characterLength, setCharacterLength] = useState(0)
  const characterLimit = 1500;
  const [alertMessage, setAlertMessage] = useState<boolean>(false);
  const [saveExceed, setSaveExceed] = useState(false)
  const navigate = useNavigate();

  const sanitizeData = (data: any) => {
    return DOMPurify.isSupported ? DOMPurify.sanitize(data, { ADD_ATTR: ['target'] }) : ''
  }

  const handleClose = () => {
    setSaveExceed(false);
    setIsEditOpen(false);
  };

  const formik = useFormik({
    onSubmit: (values) => {
        updateProjectDetail({
          projectId: values.projectId,
          aedDescription: values.description
        }).catch((e) => {
          if (!validateExpiredToken(e.message)) {
            navigate('/');
            window.location.reload();
          }
        });
        setAlertMessage(true);
        setTimeout(() => {
          setAlertMessage(false);
        }, 3000)
    },
    initialValues: {
      projectId: projectId,
      description: ""
    }
  })

  useEffect(() => {
    if(flag.current && Object.keys(projectDetailsValues).length > 0){
      flag.current = false;
      formik.setFieldValue('description',(projectDetailsValues?.data?.data[0]?.aed_description || ""))
    }
    handleEditButtonClick(isEditOpen);
  }, [projectId, formik, isEditOpen, projectDetailsValues, handleEditButtonClick])

  return (
    <div>
      <div className="card">
        <div className="card-header">
          <div className="row g-3 align-items-center">
            <div className="col d-flex align-items-center">
              <div className="flex-shrink-0">
                <div className="ratio ratio-1x1 aed-icon-box">
                  <div className={classNames("rounded-2 d-flex align-items-center justify-content-center", darkModeStatus ? "bg-lo25-primary" : "bg-l10-primary")}>
                    <label
                      className="border-0 bg-transparent me-0"
                      htmlFor="Article">
                      <Icon icon="Article" size="2x" color='primary' />
                    </label>
                  </div>
                </div>
              </div>
              <div className="flex-grow-1 ms-3 d-flex justify-content-between align-items-center">
                <div>
                  <div className="fw-bold fs-6 mb-0">
                    <h5 className="card-title">Description</h5>
                  </div>
                </div>
              </div>
            </div>       
          </div>
          <div className="card-actions">
            {aedAccess?.edit && <Button
              isOutline={!darkModeStatus}
              color='dark'
              isLight={darkModeStatus}
              className={classNames('text-nowrap', {
                  'border-light': !darkModeStatus,
              })}
              icon={'Edit'}
              onClick={() => {
                setIsEditOpen(true);
                setEditContent(formik.values.description)
              }}
            >
            </Button>}
          </div>
        </div>
        <div className="card-body">
          <div className='aed-card-description' dangerouslySetInnerHTML={{__html: sanitizeData(formik.values.description)}} />
        </div>
      </div>

      <Modal isOpen={isEditOpen} 
        setIsOpen={setIsEditOpen} 
        titleId='tour-title' 
        isCentered={true}
        size={'xl'}
        isStaticBackdrop={true}
      >
        <ModalHeader setIsOpen={setIsEditOpen}>
          <ModalTitle id='tour-title' className='d-flex align-items-end'>
            Description
          </ModalTitle>
          <Button
						color= {darkModeStatus ? 'dark' : 'light'}
            onClick={() => handleClose()}
            className={darkModeStatus ? 'modal-close-btn-dark' : 'modal-close-btn'}
            icon="Close"
          />
        </ModalHeader>
        <ModalBody>
        <FormGroup
          id="description"
        >
          <RichTextEditor
            editContent={editContent}
            setEditContent={setEditContent}
            characterLimit={characterLimit}
            setCharacterLength={setCharacterLength}
          />
          </FormGroup>
          <div className={(saveExceed && characterLength > characterLimit) ? 'des-count-exceed' : 'des-count'}>
            {saveExceed && characterLength > characterLimit && <span className='exceed-alert'>{`Total Characters can't exceed ${characterLimit}`}</span>}
            <span className={characterLength > characterLimit ? 'char-limit-exceed': 'char-limit'}>{characterLimit - characterLength}/{characterLimit}</span>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button 
            color='info'
            onClick={async () => {
              if(characterLength <= characterLimit) { 
                await setIsLoading(true);
                await formik.setFieldValue('description', editContent);
                await formik.submitForm();
                setIsEditOpen(false);
                setIsLoading(false);
              } else {
                setSaveExceed(true);
              }
            }}>Save
          </Button>
        </ModalFooter>
      </Modal>
      {
        alertMessage &&
          <Alert
          icon=''
          isLight
          color='primary'
          borderWidth={0}
          className='cgp-container-alert'
        >
          <AlertHeading tag='h2' className={classNames('text-sm cgp-container-alertHeading')}>
            Saved successfully
          </AlertHeading>
        </Alert>
      }
    </div>
  )
}


const RichTextEditor = (props: any) => {
  const blocksFromHTML = htmlToDraft(props.editContent);
  const contentState = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap);
  const [editorState, setEditorState] = useState(EditorState.createWithContent(contentState));
  const { darkModeStatus } = useDarkMode();

  const onEditorStateChange = (newEditorState: EditorState) => {
    setEditorState(newEditorState);
  };

  useEffect(() => {
    props.setEditContent(draftToHtml(convertToRaw(editorState.getCurrentContent())))
    props.setCharacterLength(editorState.getCurrentContent().getPlainText('').length)
  }, [editorState, props])

  return (
    <div>
      <Editor
        editorState={editorState}
        onEditorStateChange={onEditorStateChange}
        editorClassName={darkModeStatus ? "description-editor-dark" : "description-editor-light"}
        toolbarClassName={darkModeStatus ? "toolbar-rich-text-dark" : "toolbar-rich-text-light"}
        toolbar={{
          options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'remove', 'history'],
          inline: { options: ['bold', 'italic', 'underline', 'strikethrough', 'monospace', 'superscript', 'subscript']},
          list: { options: ['unordered', 'ordered'] },
          textAlign: { options: ['left', 'center', 'right', 'justify'] },
          colorPicker: {popupClassName: (darkModeStatus ? 'tool-popup-dark' : '')},
          fontSize: {dropdownClassName: (darkModeStatus ? 'tool-popup-dark' : '')},
          fontFamily: {dropdownClassName: (darkModeStatus ? 'tool-popup-dark' : '')},
          blockType: {dropdownClassName: (darkModeStatus ? 'tool-popup-dark' : '')},
          link: { inDropdown: false, popupClassName: (darkModeStatus ? 'link-popup tool-popup-dark' : 'link-popup'), options: ['link', 'unlink'] },
          history: { inDropdown: false },
        }}
      />
    </div>
  );
};

export default Description;
